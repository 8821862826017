/*----------------------------------------------------
@File: Default Styles
@Author: Md. Shahadat Hussain
@URL: https://themeforest.net/user/droit-themes

This file contains the styling for the actual tempalte, this
is the file you need to edit to change the look of the
tempalte.
---------------------------------------------------- */
/*=====================================================================
@Author: Md. Shahadat Hussain
@Developed By: Rony
@Developer URL: https://themeforest.net/user/droit-themes

@Default Styles

Table of Content:
01/ variables
02/ predefin
03/ button
04/ header
05/ slider
06/ baner
07/ features
08/ team
09/ services
10/ contact
11/ screenshot
12/ footer

=====================================================================*/
/*Color Variables*/
/*=========Prefix==========*/
/*----------------------------------------------------*/
/*color variables
==================================*/
/*----------------------------------------------------*/
/*
Base csss
*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

html,
body {
  height: 100%;
}

.m0 {
  margin: 0px;
}

.p0 {
  padding: 0px;
}

section.row {
  margin: 0px;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #6f7982;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden !important;
}

@media (min-width: 1250px) {
  .container {
    max-width: 1200px;
  }
}

a,
button {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-decoration: none;
}

a {
  color: #000;
}

a:hover {
  color: #4c84ff;
}

a,
a:hover,
a:focus,
button:hover,
button:focus {
  outline: 0 !important;
  text-decoration: none;
}

button:focus {
  outline: 0 !important;
}

::-moz-selection {
  background-color: #191b1d;
  color: #fff;
}

::selection {
  background-color: #191b1d;
  color: #fff;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bold {
  font-weight: 600;
}

.title-four {
  margin-bottom: 10px;
}

.title-four.no_bottom_line p {
  padding-top: 12px;
}

.title-four.no_bottom_line h2 {
  padding-bottom: 0;
}

.title-four h2 {
  font: 600 30px/45px "Poppins", sans-serif;
  color: #282835;
  padding-bottom: 10px;
}

.title-four.text-center {
  margin-bottom: 55px;
}

.title-four.mb_0 {
  margin-bottom: 0px;
}

.title-four p {
  font-family: "Poppins", sans-serif;
  max-width: 580px;
  margin: 0 auto;
  color: #585e68;
}

.title-four.text-center h5 {
  font: 400 16px/40px "Poppins", sans-serif;
  color: #8d99b3;
  letter-spacing: 0.8px;
}

.title-four.text-center .br {
  margin: 0 auto;
}

.section_title {
  text-align: center;
  max-width: 630px;
  margin: 0px auto 75px;
}

.section_title h2 {
  font-size: 36px;
  line-height: 45px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #fff;
  padding-bottom: 25px;
}

.section_title p {
  font: 400 16px/28px "Poppins", sans-serif;
  color: #fff;
}

.section_title_three {
  text-align: center;
  margin-bottom: 75px;
}

.section_title_three .title_three {
  padding-bottom: 25px;
}

.section_title_three .title_three:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.section_title_three p {
  max-width: 800px;
  margin: 33px auto 0px;
}

.title_three {
  font: 400 36px/45px "Poppins", sans-serif;
  color: #1a264a;
  text-transform: capitalize;
  position: relative;
  padding-bottom: 30px;
}

.color-b {
  color: #324865;
  font-weight: 500;
}

.color-b:before {
  background: #4c84ff;
}

.title-five {
  text-align: center;
  margin-bottom: 95px;
}

.title-five h2 {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

.title-five p {
  max-width: 650px;
  margin: 0 auto;
  color: #fff;
}

.title-five .br {
  margin: 0 auto;
}

.title-five .br:before,
.title-five .br:after {
  width: 48%;
  background: #fff;
}

.violate-dk {
  background: #654dc2;
}

.violate-l {
  background: #694fc9;
}

.section_title_two {
  text-align: center;
  margin-bottom: 100px;
}

.section_title_two h2 {
  font: 700 48px/62px "Poppins", sans-serif;
  color: #363b3e;
}

.section_title_two p {
  max-width: 800px;
  margin: 10px auto 0px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #73787c;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.35px;
}

.title-six {
  margin-bottom: 43px !important;
}

.sec_title {
  margin-bottom: 25px;
}

.sec_title h5 {
  font: 400 16px/35px "Poppins", sans-serif;
  letter-spacing: 1.6px;
  color: #666666;

  margin-bottom: 12px;
}

.sec_title h2 {
  font: 300 30px/35px "Poppins", sans-serif;
  color: #333333;
  position: relative;
  padding-bottom: 28px;
}

.sec_title h2:before {
  content: "";
  width: 30px;
  height: 2px;
  background-image: -moz-linear-gradient(0deg, #7c6bff 0%, #88e2ff 100%);
  background-image: -webkit-linear-gradient(0deg, #7c6bff 0%, #88e2ff 100%);
  background-image: -ms-linear-gradient(0deg, #7c6bff 0%, #88e2ff 100%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.sec_center {
  text-align: center;
}

.sec_center h2:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.section_title_eight {
  text-align: center;
  margin-bottom: 60px;
}

.section_title_eight h2 {
  color: #24292f;
  font: 600 36px/40px "Raleway", sans-serif;
  text-transform: capitalize;
  padding-bottom: 18px;
}

.section_title_eight p {
  font: 300 14px/24px "Poppins", sans-serif;
  color: #696c70;
  max-width: 600px;
  margin: 0 auto;
}

.display_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.display_flex .flex {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-item-align: center;
  align-self: center;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*transparent header*/
#fixed-top-sticky-wrapper,
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 999999 !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.transparent-nav {
  transition: all 0.3s;
  z-index: 999 !important;
}

.navbar-inverse .navbar-toggler-icon {
  cursor: pointer;
}

.navbar-expand-lg.navbar {
  padding: 30px 0px;
  transition: all 0.2s linear;
}

.navbar-expand-lg.navbar .navbar-brand img + img {
  display: none;
}

.navbar-expand-lg.navbar .navbar-nav li {
  margin-right: 40px;
  position: relative;
}

.navbar-expand-lg.navbar .navbar-nav li > a {
  font: 400 15px/15px "Poppins", sans-serif;
  color: #fff;
  padding: 0px;
  text-transform: capitalize;
  transition: all 0.3s linear;
  position: relative;
}

.navbar-expand-lg.navbar .navbar-nav li > a:after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  transform: scale3d(1, 0, 1);
  transform-origin: 50% 100%;
  transition: transform 0.3s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  display: inline-block;
  border: 0px;
  margin: 0px;
}

.navbar-expand-lg.navbar .navbar-nav li > a.active:after,
.navbar-expand-lg.navbar .navbar-nav li > a:hover:after {
  transform: scale3d(1, 1, 1);
  transform-origin: 50% 0%;
}

.navbar-expand-lg.navbar .navbar-nav li.submenu {
  position: relative;
}

.navbar-expand-lg.navbar .navbar-nav li.submenu .dropdown-menu {
  padding: 0px;
  margin: 0px;
  border: none;
  border-radius: 0px;
}

@media (min-width: 992px) {
  .navbar-expand-lg.navbar .navbar-nav li.submenu .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0px;
    min-width: 230px;
    text-align: left;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.2s linear;
    visibility: hidden;
    display: block;
    box-shadow: 0px 10px 30px 0px rgba(14, 0, 40, 0.1);
  }
}

.navbar-expand-lg.navbar .navbar-nav li.submenu .dropdown-menu {
  background: transparent;
  padding-top: 20px;
}

.navbar-expand-lg.navbar .navbar-nav li.submenu .dropdown-menu li {
  background: #fff;
}

.navbar-expand-lg.navbar .navbar-nav li.submenu .dropdown-menu li:first-child {
  padding-top: 15px;
}

.navbar-expand-lg.navbar .navbar-nav li.submenu .dropdown-menu li:last-child {
  padding-bottom: 15px;
  margin-bottom: -15px;
}

.navbar-expand-lg.navbar .navbar-nav li.submenu .dropdown-menu li {
  display: block;
  margin-right: 0px;
  position: relative;
}

.navbar-expand-lg.navbar .navbar-nav li.submenu .dropdown-menu li a {
  padding: 7px 25px;
  transition: all 400ms linear 0s;
  display: block;
  color: #414141;
}

.navbar-expand-lg.navbar
  .navbar-nav
  li.submenu
  .dropdown-menu
  li
  .dropdown-menu {
  transition: all 0.3s ease-in;
  display: block;
}

@media (min-width: 992px) {
  .navbar-expand-lg.navbar
    .navbar-nav
    li.submenu
    .dropdown-menu
    li
    .dropdown-menu {
    transform: translateX(20px);
    transform-origin: 0 50%;
    visibility: hidden;
    left: 100.19%;
    top: 0;
    opacity: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }
}

.navbar-expand-lg.navbar .navbar-nav li.submenu .dropdown-menu li:hover > a {
  color: #4c83ff;
  background: transparent;
}

@media (min-width: 992px) {
  .navbar-expand-lg.navbar .navbar-nav li.submenu:hover ul {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg.navbar
    .navbar-nav
    li.submenu:hover
    ul
    li:hover
    .dropdown-menu {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 1866px) and (min-width: 992px) {
  .navbar-expand-lg.navbar .navbar-nav li:last-child.submenu .dropdown-menu {
    left: auto;
    right: 0;
  }
}

@media (max-width: 1866px) and (min-width: 992px) {
  .navbar-expand-lg.navbar
    .navbar-nav
    li:last-child.submenu
    .dropdown-menu
    li
    .dropdown-menu {
    right: 100.19%;
    left: auto;
    transform: translateX(-20px);
  }
}

.navbar-expand-lg.navbar .navbar-nav li.search_dropdown a:hover:after {
  display: none;
}

.navbar-expand-lg.navbar.shrink {
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 15px 0;
}

.navbar-expand-lg.navbar.shrink .navbar-brand img {
  display: none;
}

.navbar-expand-lg.navbar.shrink .navbar-brand img + img {
  display: block;
}

.navbar-expand-lg.navbar.shrink .navbar-nav .nav-item a {
  color: #000;
  position: relative;
}

.navbar-expand-lg.navbar.shrink .navbar-nav .nav-item a.active,
.navbar-expand-lg.navbar.shrink .navbar-nav .nav-item a:hover {
  color: #4c83ff;
}

.navbar-expand-lg.navbar.shrink .navbar-nav .nav-item a.active:after,
.navbar-expand-lg.navbar.shrink .navbar-nav .nav-item a:hover:after {
  background: #4c83ff;
}

.navbar-expand-lg.navbar.shrink .navbar-nav .search_dropdown a {
  color: #000;
}

.navbar-expand-lg.navbar.shrink .get-btn {
  background-image: linear-gradient(
    120deg,
    rgb(242, 193, 19) 0%,
    rgb(251, 255, 0) 100%
  );
  color: #003459;
}

.navbar-expand-lg.navbar.shrink .get-btn:hover {
  background-image: linear-gradient(
    120deg,
    rgb(251, 255, 0) 0%,
    rgb(242, 193, 19) 100%
  );
}

.navbar-expand-lg.navbar.shrink .navbar-toggler .icon-bar {
  background: #000;
}

.navbar-toggler {
  margin-top: 0px;
  margin-right: 0px;
  border: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.navbar-toggler.collapsed .icon-bar {
  transition: all 0.5s linear;
}

.navbar-toggler.collapsed .icon-bar:nth-child(1) {
  transform: rotate(0deg);
  top: 0;
}

.navbar-toggler.collapsed .icon-bar:nth-child(2) {
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .icon-bar:nth-child(3) {
  opacity: 1;
}

.navbar-toggler .icon-bar {
  background-color: #fff;
  transition: all 0.5s linear;
  display: block;
  width: 22px;
  height: 2px;
}

.navbar-toggler .icon-bar:nth-child(1) {
  transform: rotate(45deg);
  top: 7px;
  position: relative;
}

.navbar-toggler .icon-bar:nth-child(2) {
  transform: rotate(-45deg);
}

.navbar-toggler .icon-bar:nth-child(3) {
  opacity: 0;
}

.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 5px;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  background: transparent;
}

/*nav_fluid css
========================================*/
.nav_fluid {
  padding: 15px 30px;
}

.nav_fluid .navbar-nav .nav-item:last-child {
  margin-right: 40px;
}

.nav_fluid .navbar-nav .nav-item a {
  font: 400 16px/50px "Open Sans", sans-serif;
  text-transform: capitalize;
}

.nav_fluid .get-btn {
  border-radius: 2px;
  background: #8e99ff;
  border: 0px;
  padding: 5px 50px;
}

.nav_fluid.shrink {
  padding: 10px 30px;
}

.menu_two.navbar .navbar-nav .nav-item a {
  color: #76859f;
}

.menu_two .get-btn {
  color: #b9b8b8;
  border-color: #b9b8b8;
}

.menu_two .navbar-toggler .icon-bar {
  background: #b9b8b8;
}

/*================ header area css =====================*/
.header_area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 30px 0 0;
}

.header_area .navbar-nav .nav-item {
  margin-left: 35px;
  margin-right: 0;
}

.header_area .navbar-nav .nav-item .nav-link {
  font: 400 12px/25px "Montserrat", sans-serif;
  color: #fff;
  padding: 0px;
  text-transform: capitalize;
  transition: all 0.2s linear;
  position: relative;
}

.header_area .navbar-nav .nav-item .nav-link:after {
  display: none;
}

.header_area .navbar-nav .nav-item .nav-link:before {
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  position: absolute;
  transform: scaleX(0);
  transform-origin: 0, 50%;
  transition: all 0.2s linear;
  background: #91c1f5;
}

.header_area .navbar-nav .nav-item .nav-link:hover,
.header_area .navbar-nav .nav-item .nav-link.active {
  color: #91c1f5;
}

.header_area .navbar-nav .nav-item .nav-link:hover:before,
.header_area .navbar-nav .nav-item .nav-link.active:before {
  transform: scaleX(1);
}

.header_area .navbar-right li {
  margin-left: 23px;
}

.header_area .navbar-right li a {
  position: relative;
  color: #fff;
}

.header_area .navbar-right li a:after {
  content: "";
  width: 1px;
  height: 12px;
  background: #fff;
  position: absolute;
  left: -10px;
  top: 2px;
  display: block;
}

.header_area .navbar-right li a:before {
  display: none;
}

.header_area.shrink .navbar-nav li a {
  color: #000;
}

.header_area.shrink .navbar-nav li a:hover,
.header_area.shrink .navbar-nav li a.active {
  color: #0270e9;
}

.header_area.shrink .navbar-nav li a:hover:before,
.header_area.shrink .navbar-nav li a.active:before {
  background: #0270e9;
}

.header_area.shrink .navbar-right li a:after {
  background: #000;
}

.popup-wrapper {
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  left: -100%;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
}

.popup-wrapper .bg-layer {
  position: absolute;
  left: 0;
  top: -100px;
  right: 0;
  bottom: -100px;
  background: rgba(0, 0, 0, 0.8);
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  opacity: 0;
}

.popup-wrapper .popup-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
  width: 100%;
  height: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0;
  text-align: center;
  -webkit-transition: opacity 0.3s ease-out, left 0s 0.3s,
    -webkit-transform 0.3s ease-out;
  transition: opacity 0.3s ease-out, left 0s 0.3s,
    -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out, opacity 0.3s ease-out, left 0s 0.3s;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out, left 0s 0.3s;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out, left 0s 0.3s,
    -webkit-transform 0.3s ease-out;
  -webkit-transition: transform 0.3s ease-out, opacity 0.3s ease-out,
    left 0s 0.3s;
  opacity: 0;
  left: -10000px;
  padding: 15px;
}

.popup-wrapper .popup-content .layer-close {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 10000px;
}

.popup-wrapper .popup-content .popup-container {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  position: relative;
  text-align: left;
  background: #fff;
  max-width: 570px;
}

.popup-wrapper .popup-content .popup-container.searhPopup {
  position: relative;
  background: #252525;
  padding: 70px 50px 90px;
}

.popup-wrapper .popup-content .popup-container.size-2 {
  max-width: 950px;
}

.popup-wrapper .popup-content .popup-container form {
  position: relative;
  overflow: hidden;
}

.popup-wrapper .popup-content .popup-container form h5 {
  padding: 0px;
  font: 600 28px/46px "Poppins", sans-serif;
  color: #fff;
  margin: 0px;
}

.popup-wrapper .popup-content .popup-container form .simple-input {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  padding: 0px;
  width: 100%;
  border: 1px #eee solid;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  -webkit-transition: all 0.15s;
  background: transparent;
  border: 0px;
  font-size: 16px;
  line-height: 25px;
  color: #fff;
  box-shadow: none;
}

.popup-wrapper .popup-content .popup-container form .simple-input.placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.popup-wrapper
  .popup-content
  .popup-container
  form
  .simple-input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.popup-wrapper
  .popup-content
  .popup-container
  form
  .simple-input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.popup-wrapper
  .popup-content
  .popup-container
  form
  .simple-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.popup-wrapper .popup-content .popup-container form .simple-input:focus {
  outline: none;
}

.popup-wrapper
  .popup-content
  .popup-container
  form
  .simple-input:focus
  + .searchBorderBottom:before {
  width: 100%;
}

.popup-wrapper .popup-content .popup-container form .searchBorderBottom {
  position: relative;
  height: 2px;
  width: 100%;
  background: #535353;
  content: "";
}

.popup-wrapper .popup-content .popup-container form .searchBorderBottom:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: #4356ea;
  content: "";
  -webkit-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.popup-wrapper button {
  position: absolute;
  bottom: 10px;
  right: 8px;
  width: 30px;
  height: 30px;
  border: 0px;
  background: transparent;
  cursor: pointer;
}

.popup-wrapper button i {
  font-size: 16px;
  line-height: 26px;
  color: #909090;
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
}

.popup-wrapper.active {
  left: 0;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
}

.popup-wrapper.active .bg-layer {
  opacity: 1;
}

.popup-wrapper.active .popup-content {
  transform: translateY(0);
  opacity: 1;
  left: 0;
  transition: opacity 0.3s ease-out, left 0s 0s, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out, left 0s 0s;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out, left 0s 0s,
    -webkit-transform 0.3s ease-out;
  position: relative;
}

.popup-wrapper .button-close {
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  opacity: 0.6;
}

.popup-wrapper .button-close i {
  display: inline-block;
  font-size: 20px;
}

.popup-wrapper .button-close:hover {
  opacity: 1;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.banner_btn {
  font-size: 14px;
  line-height: 58px;
  text-align: center !important;
  letter-spacing: 1.4px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  width: 220px;
  height: 60px;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
  border: 2px solid transparent;
  transition: all 0.5s linear;
  color: #fff;
}

.banner_btn + .banner_btn {
  margin-left: 30px;
}

.btn-white {
  background: #fff;
  color: #414141;
}

.btn-white:hover {
  background: transparent;
  color: #fff;
  border-color: #fff;
}

.btn-transparent {
  border-color: #fff;
  color: #fff;
}

.btn-transparent:hover {
  background: #fff;
  color: #414141;
}

.get-btn {
  font: 400 14px/14px "Poppins", sans-serif;
  color: #003459;
  text-transform: capitalize;
  padding: 15px 28px;
  border-radius: 3px;
  display: inline-block;
  transition: all 0.4s linear;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 29, 0.07);
  letter-spacing: 0;
  background-image: linear-gradient(
    120deg,
    rgb(242, 193, 19) 0%,
    rgb(251, 255, 0) 100%
  );
}

.get-btn:hover {
  background-image: linear-gradient(
    120deg,
    rgb(251, 255, 0) 0%,
    rgb(242, 193, 19) 100%
  );
}

.learn-btn-two {
  font-size: 14px;
  line-height: 50px;
  padding: 0px 52px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background: #4c83ff;
  text-transform: uppercase;
  display: inline-block;
  border: 0px;
  border-radius: 40px;
  transition: all 0.4s linear;
}

.learn-btn-two:hover {
  background: #2f6efd;
  color: #fff;
}

.app-btn {
  display: inline-block;
  width: 200px;
  height: 60px;
  border: 1px solid #fff;
  border-radius: 5px;
  text-align: center;
  line-height: 57px;
  transition: all 0.3s linear;
  background: #fff;
}

.app-btn img + img {
  display: none;
  text-align: center;
}

.app-btn + .app-btn {
  margin-left: 8px;
  background: transparent;
}

.app-btn + .app-btn img {
  display: none;
}

.app-btn + .app-btn img + img {
  display: inline-block;
}

.app-btn + .app-btn:hover {
  background: #fff;
}

.app-btn + .app-btn:hover img {
  display: inline-block;
}

.app-btn + .app-btn:hover img + img {
  display: none;
}

.app-btn:hover {
  background: transparent;
}

.app-btn:hover img {
  display: none;
}

.app-btn:hover img + img {
  display: inline-block;
}

.learn_btn {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1.4px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #4c83ff;
  z-index: 0;
  border-radius: 50px;
  text-transform: uppercase;
  border: 1px solid #4c83ff;
  padding: 14px 40px;
  transition: all 0.5s linear;
}

.learn_btn:hover {
  background: #4c83ff;
  color: #fff;
}

.learn_btn_two {
  font: 700 14px/36px "Open Sans", sans-serif;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #fff;
  padding: 7px 37px;
  border-radius: 2px;
  border: 0px;
  background-image: -moz-linear-gradient(-90deg, #85c6ff 0%, #7f8cff 100%);
  background-image: -webkit-linear-gradient(-90deg, #85c6ff 0%, #7f8cff 40%);
  background-image: -ms-linear-gradient(-90deg, #85c6ff 0%, #7f8cff 100%);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  margin-top: 45px;
  background-size: 200% auto;
}

.learn_btn_two:hover {
  background-position: right center;
  color: #fff;
}

.hero_btn {
  padding: 0px 31px;
  font: 600 12px/55px "Raleway", sans-serif;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid #1c81f0;
  border-radius: 2px;
  background-color: #0f78eb;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1;
  overflow: hidden;
  transform: perspective(1px) translateZ(0);
  transition: all 0.2s linear;
}

.hero_btn i {
  font-size: 15px;
  vertical-align: middle;
  padding-right: 10px;
}

.hero_btn:before {
  content: "";
  width: 100%;
  height: 0;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  background: #fff;
  transition: all 0.3s linear;
}

.hero_btn:hover {
  border-color: #fff;
  color: #0270e9;
}

.hero_btn:hover:before {
  height: 300%;
}

.btn_wht {
  background: #fff;
  padding: 0px 40px;
  color: #0270e9;
  border-color: transparent;
  margin-left: 20px;
}

.btn_wht:before {
  background-color: #0f78eb;
}

.btn_wht:hover {
  border-color: #0f78eb;
  color: #fff;
}

.app_btn {
  display: inline-block;
  background: #0270e9;
  padding: 5px 15px;
  padding: 6px 20px;
  border-radius: 3px;
  border: 1px solid transparent;
}

.btn_white {
  background: #fff;
  border-color: #0270e9;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  margin-left: 15px;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Start hero-banner-area css
================================================================*/
.hero-banner-area {
  height: 1000px;
  position: relative;
  overflow: hidden;
}

.hero-banner-area .display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.hero-banner-area .flex {
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.hero-banner-area .shape {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  overflow: hidden;
}

.hero-banner-area .shape:before {
  content: "";
  width: 150%;
  height: 116px;
  background: #616dfe;
  position: absolute;
  z-index: -5;
  opacity: 0.23;
  -webkit-transform: rotate(-3.9deg);
  -ms-transform: rotate(-3.9deg);
  transform: rotate(-3.9deg);
  bottom: 44px;
  right: -171px;
  -webkit-transform-origin: 50% 0 0;
  -ms-transform-origin: 50% 0 0;
  transform-origin: 50% 0 0;
  top: auto;
}

.hero-banner-area .shape:after {
  content: "";
  width: 80%;
  height: 95%;
  background-image: -moz-linear-gradient(
    0deg,
    rgba(76, 132, 255, 0.1) 0%,
    rgba(120, 61, 255, 0.1) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgba(76, 132, 255, 0.1) 0%,
    rgba(120, 61, 255, 0.1) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgba(76, 132, 255, 0.1) 0%,
    rgba(120, 61, 255, 0.1) 100%
  );
  top: 0;
  right: -171px;
  z-index: -1;
  position: absolute;
  -webkit-transform: rotate(4deg);
  -ms-transform: rotate(4deg);
  transform: rotate(4deg);
}

.banner-content {
  /*  max-width: 575px;*/
  margin-left: auto;
  margin-right: 0px;
  color: #fff;
}

.banner-content h1 {
  font: 500 50px/55px "Poppins", sans-serif;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.banner-content p {
  font: 400 18px/35px "Poppins", sans-serif;
}

.col-header-img {
  position: relative;
}

.col-header-img img {
  position: absolute;
}

.col-header-img .img-header-lg {
  bottom: 190px;
  left: 0;
}

.col-header-img .img-header-sm {
  bottom: 70px;
  z-index: 20;
  left: 45px;
}

/* End hero-banner-area css
================================================================*/
/*hero-two css
=============================================================*/
.hero-two {
  background-image: -moz-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -webkit-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -ms-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  height: 960px;
  position: relative;
  z-index: 1;
  padding-top: 260px;
}

.hero-two:after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background-size: cover;
  z-index: -1;
}

.hero-content {
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
}

.hero-content h1 {
  font-size: 60px;
  line-height: 75px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.hero-content p {
  font-size: 22px;
  line-height: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin: 15px 0px 60px;
}

.hero-content .banner_btn {
  font-weight: 500;
  font-size: 15px;
}

.hero-content .banner_btn.btn-white {
  color: #4c84ff;
}

.hero-content .banner_btn.btn-white:hover {
  color: #fff;
}

.hero-content .banner_btn.btn-transparent:hover {
  color: #4c84ff;
}

.mockup-flow {
  position: absolute;
  left: 51%;
  width: 100%;
  text-align: center;
}

.mockup-flow img {
  max-width: 100%;
}

/*hero-three css
===================================*/
.hero-three {
  background-image: -webkit-linear-gradient(-15deg, #1bdbce 0%, #8a41f0 100%);
  background-image: -moz-linear-gradient(-15deg, #1bdbce 0%, #8a41f0 100%);
  background-image: -ms-linear-gradient(-15deg, #1bdbce 0%, #8a41f0 100%);
  background-image: linear-gradient(-15deg, #1bdbce 0%, #8a41f0 100%);
  padding-top: 160px;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.hero-three:before {
  content: "";
  width: 100%;
  height: 543px;
  bottom: -36px;
  position: absolute;
  left: 0;
  z-index: 0;
}

.hero-three .hero-content {
  padding-bottom: 60px;
}

.hero-three .hero-content h1 {
  font-size: 50px;
  line-height: 55px;
}

.hero-three .hero-content p {
  margin-bottom: 50px;
}

.hero-three .hero-content .banner_btn.btn-white {
  color: #3dacd9;
}

.hero-three .hero-content .banner_btn.btn-white:hover {
  color: #fff;
}

.hero-three .hero-content .banner_btn.btn-transparent:hover {
  color: #3dacd9;
}

.mockup_img {
  text-align: right;
  position: relative;
  padding-right: 30px;
}

.mockup_img .small_img {
  position: absolute;
  left: 60px;
  bottom: 0;
}

/* hero-four css
==============================================*/
.display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.display-flex .flex {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-item-align: center;
  align-self: center;
  vertical-align: middle;
}

.hero-four {
  height: 950px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hero-four .display-flex {
  height: 100%;
}

.hero-four .slider_image {
  position: absolute;
  right: 0;
  width: 850px;
  height: 100%;
}

.hero-four .slider_image img {
  position: absolute;
}

.hero-four .slider_image .slider1-img {
  left: -18%;
  bottom: -126px;
}

.hero-four .slider_image .slider2-img {
  right: 0;
  bottom: -50px;
}

.hero-four .banner-content {
  -ms-flex-item-align: center;
  align-self: center;
  max-width: 100%;
  margin-left: 0px;
}

.hero-four .banner-content p {
  font-size: 22px;
  margin: 25px 0px 40px;
}

.hero-four .banner-content .banner_btn {
  color: #557fff;
}

.hero-four .banner-content .banner_btn.btn-transparent {
  color: #fff;
}

.hero-four .banner-content .banner_btn.btn-transparent:hover {
  color: #557fff;
}

.hero-four .banner-content .banner_btn:hover {
  color: #fff;
}

/* Start hero-banner-six area css
============================================================*/
.hero-banner-six .col-header-img img {
  bottom: -20px;
}

.hero-banner-six .banner-content {
  max-width: 555px;
}

.hero-banner-six .btn-white {
  color: #557fff;
}

.hero-banner-six .btn-white:hover {
  color: #fff;
}

.hero-banner-six .btn-transparent:hover {
  color: #fff;
}

.banner_area_seven {
  background-image: -moz-radial-gradient(
    50% 50%,
    circle closest-side,
    #85c6ff 0%,
    #7f8cff 100%
  );
  background-image: -webkit-radial-gradient(
    50% 50%,
    circle closest-side,
    #85c6ff 0%,
    #7f8cff 100%
  );
  background-image: -ms-radial-gradient(
    50% 50%,
    circle closest-side,
    #85c6ff 0%,
    #7f8cff 100%
  );
  height: 950px;
  overflow: visible;
  position: relative;
  z-index: 1;
}

.banner_area_seven:before {
  content: "";
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: url("../images/home7/banner-bg.png") no-repeat scroll center
    bottom;
  position: absolute;
  z-index: -1;
}

.banner_area_seven .banner-content {
  margin-left: 0;
  padding-top: 310px;
}

.banner_area_seven .banner-content h1 {
  font: 300 60px "Poppins", sans-serif;
  letter-spacing: 0px;
}

.banner_area_seven .banner-content p {
  font-size: 20px;
  margin: 22px 0px 40px;
}

.banner_area_seven .banner-content .banner_btn {
  border-radius: 2px;
  font: 700 16px/56px "Poppins", sans-serif;
  letter-spacing: 0.16px;
  color: #7f8cff;
}

.banner_area_seven .banner-content .banner_btn:hover {
  color: #fff;
}

.banner_area_seven .col-header-img-two .img-header-lg {
  padding-top: 175px;
  float: right;
}

/*=========================== hero_banner_area css =========================*/
.hero_banner_area {
  background: #0270e9;
  padding: 257px 0px 156px;
  position: relative;
  overflow: hidden;
}

.hero_banner_area .shape {
  position: absolute;
  top: 0;
  width: 100%;
}

.hero_banner_area .shape .layer2 {
  left: 0;
  top: 0;
}

.hero_banner_area .shape .layer3 {
  bottom: 0;
  right: 0;
}

.hero_banner_area .hero_content {
  color: #fff;
}

.hero_banner_area .hero_content h2 {
  font: 400 48px/58px "Raleway", sans-serif;
}

.hero_banner_area .hero_content h2 strong {
  font-weight: 800;
}

.hero_banner_area .hero_content p {
  font: 300 14px/24px "Poppins", sans-serif;
  padding: 25px 0 43px;
}

.hero_banner_area .h_phone_img {
  position: relative;
  text-align: right;
  z-index: 1;
}

.hero_banner_area .h_phone_img .phone_sm {
  position: absolute;
  left: -30px;
  top: 46px;
  z-index: -1;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.page-main {
  height: 960px;
  position: relative;
  overflow: hidden;
}

.page-main .display-flex {
  height: 100%;
}

.page-main .page_contain h2 {
  font: 300 60px/70px "Poppins", sans-serif;
  letter-spacing: -0.12px;
  color: #1a264a;
}

.page-main .page_contain h2 span {
  font-weight: 700;
}

.page-main .page_contain p {
  font: 400 18px/30px "Poppins", sans-serif;
  color: #76859f;
  padding: 30px 0px 50px;
}

.page-main .page_contain .banner_btn:hover {
  background: transparent;
  color: #ba77ff;
}

.page-main .images-container {
  margin-right: 0px;
  text-align: right;
  position: relative;
  left: 70px;
  top: 20px;
}

.page-main .images-container img {
  max-width: 100%;
  display: inline-block;
}

.first .circle {
  position: absolute;
  border-radius: 50%;
}

.first .circle.circle-1 {
  width: 900px;
  height: 900px;
  top: -17%;
  left: 150px;
  background-color: #9788ff;
  opacity: 0.1;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(89, 50, 123, 0.5);
  box-shadow: 0px 0px 30px 0px rgba(89, 50, 123, 0.5);
}

.first .circle.circle-2 {
  width: 800px;
  height: 800px;
  top: 100px;
  right: 150px;
  background-color: #feb4d7;
  opacity: 0.15;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(195, 107, 148, 0.3);
  box-shadow: 0px 0px 30px 0px rgba(195, 107, 148, 0.3);
}

.first .circle.circle-3 {
  width: 600px;
  height: 600px;
  top: 200px;
  right: 250px;
  background-color: #feb4d7;
  opacity: 0.15;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(195, 107, 148, 0.3);
  box-shadow: 0px 0px 30px 0px rgba(195, 107, 148, 0.3);
}

/*---------------------------------------------------- */

.n_hero_banner_area {
  min-height: 1010px;
  position: relative;
  z-index: 1;
  padding-top: 250px;
  background-image: -moz-linear-gradient(40deg, #003459 0%, #0095ff 100%);
  background-image: -webkit-linear-gradient(40deg, #003459 0%, #0095ff 100%);
  background-image: -ms-linear-gradient(40deg, #003459 0%, #0095ff 100%);
  background-image: linear-gradient(40deg, #003459 0%, #0095ff 100%);
  overflow: hidden;
}

.n_banner_content {
  color: #fff;
  font-family: "Poppins", sans-serif;
  padding-top: 60px;
  position: relative;
  z-index: 1;
}

.n_banner_content h2 {
  font-size: 50px;
  line-height: 66px;
  font-weight: 700;
}

.n_banner_content p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  padding: 20px 55px 44px 0px;
}

.n_banner_btn {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  border-radius: 3px;
  background-image: -moz-linear-gradient(
    to right,
    #4a77f4 0%,
    #4c84ff 51%,
    #4a77f4 100%
  );
  background-image: -webkit-linear-gradient(
    to right,
    #4a77f4 0%,
    #4c84ff 51%,
    #4a77f4 100%
  );
  background-image: -ms-linear-gradient(
    to right,
    #4a77f4 0%,
    #4c84ff 51%,
    #4a77f4 100%
  );
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 29, 0.14) !important;
  background-image: linear-gradient(
    to right,
    #4a77f4 0%,
    #4c84ff 51%,
    #4a77f4 100%
  );
  background-size: 200% auto;
  padding: 18px 33px;
  font-weight: 600;
  color: #fff;
  display: inline-block;
  transition: all 0.3s linear;
}

.n_banner_btn:hover {
  background-position: right center;
}

.n_hero_banner_area .mobile_img {
  position: relative;
  z-index: 2;
}

.n_hero_banner_area .mobile_img img {
  max-width: inherit;
  position: relative;
  z-index: 1;
  top: -120px;
}

.n_hero_banner_area svg {
  position: absolute;
  bottom: -29px;
  height: 350px;
  width: 100%;
  left: 0;
}

.shape_banners {
  position: absolute !important;
  right: 0;
  top: 0;
  height: 100%;
  width: 50%;
}

.shape_banners .img1 {
  right: 120px;
  position: absolute;
  top: 176px;
}

.shape_banners .img2 {
  left: 24px;
  top: 358px;
  position: absolute;
}

.shape_banners .img3 {
  top: 200px;
  position: absolute;
  left: 80px;
}

.about_area_paralux {
  padding-top: 200px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.about_area_paralux .shape {
  position: absolute;
  top: 0;
  right: 80px;
  z-index: -1;
}

.align_items_center {
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.about_area_paralux .about_img {
  margin-left: 30px;
}

.about_area_paralux .about_img {
  position: relative;
}

.about_area_paralux .about_img img,
.faq_solution_area .faq_image_mockup img {
  max-width: 100%;
}

.h_color {
  color: #282835;
  font-weight: 600;
  line-height: 50px;
  font-family: "Poppins", sans-serif;
}

.f_36 {
  font-size: 36px;
}

.about_area_paralux .about_content p {
  padding-top: 30px;
}

.about_area_paralux .about_content .n_banner_btn {
  margin-top: 70px;
}

.n_call_action_content {
  padding: 130px;
  padding-right: 0;
}

.new_call_action_area .action_mobile {
  top: -145px;
}

@media (max-width: 1280px) {
  .n_hero_banner_area {
    min-height: auto;
    padding-top: 170px !important;
  }

  .n_banner_content {
    flex: 1;
    justify-content: center;
    align-self: center;
  }

  .n_hero_banner_area .mobile_img img {
    max-width: 100%;
    top: -58px;
  }

  .n_hero_banner_area svg {
    display: none;
  }
}

/*---------------------------------------------------- */

/*----------------------------------------------------*/
/* Start features-area3 css
==================================================*/
.features-area3 {
  overflow: hidden;
}

.features-area3 .section-title {
  margin-bottom: 74px;
}

.features-area3 .features-tab {
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}

.features-area3 .features-tab li {
  margin: 0px;
  width: 25%;
}

.features-area3 .features-tab li a {
  font: 400 15px/28px "Poppins", sans-serif;
  color: #1a264a;
  margin: 0px;
  border-radius: 0px;
  border: 0px;
  padding: 20px 0px;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: block;
}

.features-area3 .features-tab li a:hover,
.features-area3 .features-tab li a:focus {
  background: #4c84ff;
  color: #fff;
  border-color: #4c84ff;
}

.features-area3 .features-tab li a.active {
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  background: #4c84ff;
  border-left: 1px solid #4c84ff;
  color: #fff;
}

.features-area3 .features-tab li + li a {
  border-left: 1px solid #e0e0e0;
}

.features-area3 .tab-content {
  padding-top: 80px;
}

.features-area3 .tab-content .tab-pane .overview-details {
  padding-right: 0px;
  padding-top: 10px;
  max-width: 100%;
  padding-left: 0px;
}

.features-area3 .tab-content .tab-pane .overview-details p {
  font: 400 18px/32px "Poppins", sans-serif;
  color: #585e68;
}

.features-area3 .tab-content .tab-pane .feat-img {
  -webkit-box-shadow: 0px 30px 70px 0px rgba(26, 38, 74, 0.25);
  box-shadow: 0px 30px 70px 0px rgba(26, 38, 74, 0.25);
}

.features-area3 .tab-content .tab-pane .feat-img img {
  max-width: 100%;
}

.features-area3 .tab-content .tab2 .overview-details {
  padding-right: 0px;
  padding-left: 100px;
}

/* End features-area3 css
==================================================*/
/*features_area_three css
==================================================*/
.feature-bg {
  position: relative;
}

.features_area {
  padding: 15px 0px 120px;
}

.features_content_three {
  padding-top: 130px;
}

.features_content_three > p {
  font-size: 18px;
  padding-bottom: 30px;
}

.features_content_three p + p {
  margin: 0px 0px 40px;
}

.features_content_three .media {
  margin-top: 0px;
  margin-bottom: 20px;
}

.features_content_three .media .media-left {
  padding-right: 20px;
}

.scree_feature_item {
  margin-bottom: 35px;
}

.reverse_column {
  flex-direction: row-reverse;
}

.features_content_three .media .media-left .icon {
  font-size: 20px;
  color: #4c84ff;
  line-height: 50px;
  background: #fff;
  width: 50px;
  height: 50px;
  border: 1px solid #e7e7e7;
  text-align: center;
  border-radius: 50%;
  padding: 0px;
}

.features_content_three .media .media-body {
  color: #585e68;
  font-size: 18px;
  line-height: 32px;
  vertical-align: middle;
  padding-top: 9px;
}

.features_area .f_img img {
  max-width: 100%;
  padding-right: 65px;
}

.features_area_two {
  padding: 138px 0px 130px;
}

.body_wrapper {
  overflow: hidden;
}

.features_content_seven {
  vertical-align: middle;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.features_content_seven p {
  font: 400 16px/28px "Poppins", sans-serif;
  color: #777777;
}

.f_img_seven {
  position: relative;
}

.f_img_seven .features_img {
  position: absolute;
  right: 10px;
  top: 49%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}

.features_seven {
  padding-bottom: 130px;
}

/*=============== start pricing-area css ===================*/
.pricing-area {
  padding: 104px 0px 120px;
}

.price-tab {
  max-width: 570px;
  margin: 40px auto 50px;
  border: 0px;
}

.price-tab .nav-item {
  padding: 0px;
  margin: 0px;
  width: 50%;
}

.price-tab .nav-item .nav-link {
  padding: 0px 50px;
  font: 700 16px/60px "Poppins", sans-serif;
  color: #1a264a;

  margin: 0px;
  letter-spacing: 1.6px;
  border: 1px solid #e2e5e7;
  border-radius: 40px 0px 0px 40px;
  text-align: center;
}

.price-tab .nav-item .nav-link:hover,
.price-tab .nav-item .nav-link:focus {
  background: #f8fafc;
}

.price-tab .nav-item .nav-link.active {
  background: #f8fafc;
  border: 1px solid #e2e5e7;
}

.price-tab .nav-item .nav-link.active:hover,
.price-tab .nav-item .nav-link.active:focus {
  background: #f8fafc;
  border-color: #e2e5e7;
}

.price-tab .nav-item:last-child a {
  border-radius: 0px 40px 40px 0px;
}

.priceing-tab {
  padding: 80px 0px 0px;
}

.price .pricing-box {
  border-radius: 8px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 17px 0px rgba(26, 38, 74, 0.09);
  box-shadow: 0px 2px 17px 0px rgba(26, 38, 74, 0.09);
  text-align: center;
  padding: 0px 0px 60px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.2s linear;
}

.price .pricing-box .pricing-header {
  position: relative;
  background-image: -moz-linear-gradient(
    44deg,
    rgb(77, 183, 255) 1%,
    rgb(96, 108, 254) 100%
  );
  background-image: -webkit-linear-gradient(
    44deg,
    rgb(77, 183, 255) 1%,
    rgb(96, 108, 254) 100%
  );
  background-image: -ms-linear-gradient(
    44deg,
    rgb(77, 183, 255) 1%,
    rgb(96, 108, 254) 100%
  );
  background-image: linear-gradient(
    44deg,
    rgb(77, 183, 255) 1%,
    rgb(96, 108, 254) 100%
  );
  padding: 36px 0px 20px;
  transition: all 0.2s linear;
}

.price .pricing-box .pricing-header h2 {
  font: 600 22px/60px "Poppins", sans-serif;
  color: #fff;
}

.price .pricing-box .pricing-header .packeg_typ {
  font: 500 65px/60px "Poppins", sans-serif;
  color: #fff;
  padding: 0px 0px 30px;
}

.price .pricing-box .pricing-header .packeg_typ span {
  font: 400 24px/35px "Poppins", sans-serif;
  letter-spacing: 1px;
  vertical-align: top;
}

.price .pricing-box .pricing-header .packeg_typ small {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 30px;
  vertical-align: bottom;
  color: #fff;
}

.price .pricing-box .plan-lists {
  padding: 47px 0px 30px;
}

.price .pricing-box .plan-lists li {
  font: 400 16px/40px "Poppins", sans-serif;
  color: #999999;
}

.price .pricing-box:hover {
  -webkit-box-shadow: 0px 17px 37px 0px rgba(26, 38, 74, 0.25);
  box-shadow: 0px 17px 37px 0px rgba(26, 38, 74, 0.25);
  margin-top: -15px;
  padding-bottom: 70px;
}

.price .pricing-box:hover .pricing-header {
  padding: 44px 0px 30px;
  background-image: -moz-linear-gradient(
    44deg,
    rgb(31, 168, 255) 1%,
    rgb(58, 72, 255) 100%
  );
  background-image: -webkit-linear-gradient(
    44deg,
    rgb(31, 168, 255) 1%,
    rgb(58, 72, 255) 100%
  );
  background-image: -ms-linear-gradient(
    44deg,
    rgb(31, 168, 255) 1%,
    rgb(58, 72, 255) 100%
  );
  background-image: linear-gradient(
    44deg,
    rgb(31, 168, 255) 1%,
    rgb(58, 72, 255) 100%
  );
}

.price .pricing-box:hover .purchase-btn {
  background: #4c84ff;
  color: #fff;
}

.price .pricing-box.active {
  -webkit-box-shadow: 0px 17px 37px 0px rgba(26, 38, 74, 0.25);
  box-shadow: 0px 17px 37px 0px rgba(26, 38, 74, 0.25);
}

.price .pricing-box.active:hover .purchase-btn {
  background: #4c84ff;
  color: #fff;
}

.try {
  display: block;
  font: 400 12px/50px "Poppins", sans-serif;
  color: #b8b8b8;

  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}

.try:hover {
  color: #4c84ff;
}

.purchase-btn {
  font-size: 14px;
  color: #4c84ff;
  font-weight: 600;
  letter-spacing: 1.4px;
  background: #e6f5ff;
  border-radius: 45px;
  display: inline-block;
  padding: 2px 60px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  line-height: 50px;
}

/*=============== End pricing-area css ===================*/
.pricing-area-two .title-five h2 {
  color: #1a264a;
}

.pricing-area-two .price .pricing-box .pricing-header .packeg_typ {
  color: #ba77ff;
}

.pricing-area-two .price .pricing-box .pricing-header .packeg_typ small {
  color: #ba77ff;
}

.pricing-area-two .price .pricing-box .purchase-btn {
  border-radius: 40px;
  border-color: #ba77ff;
  color: #ba77ff;
}

.pricing-area-two .price .pricing-box:hover .purchase-btn {
  background: #ba77ff;
  color: #fff;
}

.pricing-area-two .try:hover {
  color: #ba77ff;
}

/*================= Start call-action-area css================== */
.call-action-area {
  padding: 158px 0px 147px;
  position: relative;
  overflow: hidden;
  background-image: -moz-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -webkit-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -ms-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  z-index: 1;
}

.video-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: -2;
  opacity: 0.1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.call-action .call-text {
  color: #fff;
}

.call-action .call-text .title-w {
  margin-bottom: 40px;
}

.call-action .call-text .title-w h2 {
  text-transform: capitalize;
  font-weight: 400;
}

.call-action .call-text .title-w .br {
  margin: 0;
}

.call-action .call-text .title-w .br:before,
.call-action .call-text .title-w .br:after {
  background: #fff;
}

.call-action .call-text h2 {
  font: 700 35px/60px "Poppins", sans-serif;
  padding-bottom: 17px;
  color: #fff;
}

.call-action .call-text p {
  font: 400 16px/28px "Poppins", sans-serif;
  padding-bottom: 42px;
  padding-right: 110px;
}

.call-action .call-mobile-img {
  position: relative;
  z-index: 1;
}

.call-action .call-mobile-img img {
  position: absolute;
  bottom: -410px;
  z-index: -1;
}

.call-action .call-mobile-img img + img {
  right: 0px;
  z-index: -1;
}

.call-action-three {
  background: #fd9f3e;
}

/*================= End call-action-area css================== */
.call-action-four {
  background: #654dc2;
  padding: 120px 0px 0px;
}

.call-action-four .title-five {
  text-align: left;
  margin-bottom: 40px;
}

.call-action-four .title-five h2 {
  text-transform: capitalize;
}

.call-action-four .title-five .br {
  margin: 0;
}

.call-action-four .app-btn {
  background: #6f55d4;
  border: transparent;
}

.call-action-four .call-action .call-text {
  padding-top: 100px;
}

.call-action-four .call-mobile-img {
  min-height: 542px;
}

.call-action-four .call-mobile-img img {
  top: auto;
  bottom: 0;
}

/*=================ex-features-area css ==========================*/
.ex-features-area {
  padding: 200px 0px 170px;
}

.ex_features_item i {
  font-size: 42px;
  color: #4c84ff;
  margin-bottom: 15px;
  display: inline-block;
}

.ex_features_item .title {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  line-height: 60px;
  color: #4b678b;
  text-transform: capitalize;
}

.ex_features_item p {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  font-size: 15px;
  line-height: 28px;
  color: #6f7982;
}

/*ex-featured css
===========================================================*/
.ex-featured {
  padding: 0px;
  background: #f5f9fc;
}

.ex-featured .f_img {
  position: absolute;
  top: -42px;
}

.ex-featured .f_img img {
  border-radius: 49px;
  background-image: -moz-linear-gradient(-90deg, white 0%, #fafbfc 100%);
  background-image: -webkit-linear-gradient(-90deg, white 0%, #fafbfc 100%);
  background-image: -ms-linear-gradient(-90deg, white 0%, #fafbfc 100%);
  -webkit-box-shadow: 10.751px 28.007px 35px 0px rgba(173, 186, 204, 0.25),
    0px 7px 14px 0px rgba(0, 21, 64, 0.05),
    0px 1px 4px 0px rgba(0, 21, 64, 0.14),
    inset 0px -16px 32px 0px rgba(0, 0, 0, 0.08),
    inset 0px 3px 5px 0px rgba(255, 255, 255, 0.004);
  box-shadow: 10.751px 28.007px 35px 0px rgba(173, 186, 204, 0.25),
    0px 7px 14px 0px rgba(0, 21, 64, 0.05),
    0px 1px 4px 0px rgba(0, 21, 64, 0.14),
    inset 0px -16px 32px 0px rgba(0, 0, 0, 0.08),
    inset 0px 3px 5px 0px rgba(255, 255, 255, 0.004);
  position: relative;
  z-index: 2;
}

.ex-featured .features_content {
  padding-top: 134px;
  padding-bottom: 148px;
}

.ex-featured .features_content .color-b {
  font-weight: 500;
  text-transform: capitalize;
  color: #324865;
  padding-bottom: 0;
}

.ex-featured .features_content p {
  color: #6f7982;
  padding: 35px 0px 47px;
}

/*fluid-promo-area css
======================================================*/
.promo-featured-area {
  padding: 160px 0px;
}

.promo-inner-left {
  width: calc(50% - 120px);
  float: left;
}

.promo-inner-left img {
  max-width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 5px 25px 0px rgba(5, 5, 5, 0.2);
  box-shadow: 0px 5px 25px 0px rgba(5, 5, 5, 0.2);
}

.promo-inner-right {
  width: calc(50% + 120px);
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-item-align: center;
  align-self: center;
  float: right;
}

.promo-inner-right .overview-details {
  padding-top: 0px;
  padding-left: 120px;
  max-width: 705px;
}

.promo-inner-right .overview-details h2 {
  font: 500 40px/55px "Poppins", sans-serif;
  color: #324865;
  padding-bottom: 40px;
}

.promo-inner-right .overview-details p {
  font: 400 16px/28px "Poppins", sans-serif;
  color: #6f7982;
  padding-bottom: 58px;
}

.promo-inner-right .overview-details .learn-btn-two {
  font: 500 15px/50px "Poppins", sans-serif;
  color: #4c84ff;
  background: #fff;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(76, 132, 255, 0.2);
  box-shadow: 0px 2px 20px 0px rgba(76, 132, 255, 0.2);
}

.promo-inner-right .overview-details .learn-btn-two:hover {
  background: #4c84ff;
  color: #fff;
}

/*============more_feature area ===========*/
.more_feature_area {
  padding: 150px 0px 70px;
}

.more_features .media {
  margin-bottom: 75px;
}

.more_features .media .media-left {
  padding: 0px 30px 0px 18px;
}

.more_features .media .media-left i {
  font-size: 40px;
  color: #3dacd9;
}

.more_features .media .media-body h2 {
  font-weight: 600;

  font-size: 18px;
  font-family: "Poppins", sans-serif;
  padding-bottom: 20px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  color: #404040;
}

.more_features .media .media-body p {
  font-size: 16px;
  line-height: 28px;
  color: #747474;
}

/* powerfull_features css
=====================================================*/
.powerfull_features {
  background: #56d090;
  padding: 130px 0px;
}

.powerfull_features .power-img {
  -ms-flex-item-align: center;
  align-self: center;
}

.powerfull_features .app-promo-content-two {
  padding-left: 35px;
  padding-top: 68px;
}

.powerfull_features .app-promo-content-two .promo-button {
  margin-top: 12px;
}

.powerfull_features .app-promo-content-two .promo-button .banner_btn {
  color: #56d090;
}

.powerfull_features .app-promo-content-two .promo-button .banner_btn:hover {
  color: #fff;
}

.powerfull_features .app-promo-content-two .promo-item {
  padding-right: 50px;
}

/*business_features_area css
====================================================*/
.business_features_area {
  background: #807fff;
  overflow: hidden;
}

.b_features_content {
  color: #ffffff;
  padding: 246px 0px;
  z-index: 2;
  position: relative;
}

.b_features_content h2 {
  font-size: 48px;
  font-family: "Poppins", sans-serif;
  line-height: 62px;
  font-weight: 700;
  padding-bottom: 35px;
}

.b_features_content p {
  font: 400 16px/28px "Lato", sans-serif;
  padding-bottom: 35px;
}

.b_features_content .banner_btn {
  height: 50px;
  line-height: 50px;
  color: #807fff;
  width: 180px;
}

.b_features_content .banner_btn:hover {
  color: #fff;
}

.b_features_content .app-store-btn {
  margin-top: 50px;
}

.b_features_content .app-store-btn a {
  font: 700 20px "Lato", sans-serif;
  color: #fff;
}

.b_features_content .app-store-btn a i {
  padding-right: 20px;
}

.b_features_content .app-store-btn a + a {
  margin-left: 20px;
}

.phone-mockup {
  position: relative;
  height: 100%;
  z-index: 1;
}

.phone-mockup img {
  position: absolute;
  z-index: -1;
}

.phone-mockup .phone-one {
  top: 0;
  left: -43px;
}

.phone-mockup .phone-two {
  right: -155px;
  top: 25px;
  z-index: 0;
}

.phone-mockup .phone-three {
  right: -165px;
  bottom: 0;
}

/*Start Ex features area four css
=======================================================*/
.ex-features-area-four {
  padding: 140px 0px;
}

.ex-features-area-four .ex_features_item i {
  color: #fff;
}

.ex-features-area-four .ex_features_item .title,
.ex-features-area-four .ex_features_item p {
  color: #fff;
}

/*Start features area four css
=======================================================*/
.features-area-four {
  padding: 145px 0px 150px;
}

.features-info {
  padding: 0px 50px 0px 35px;
}

.features-info .features-item {
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.features-info .features-item i {
  width: 65px;
  height: 65px;
  line-height: 65px;
  font-size: 28px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}

.features-info .features-item h2 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;

  padding: 20px 0px 10px;
}

.features-info .features-item p {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
}

.features-info .features-item + .features-item {
  margin-top: 110px;
}

.features-info.f-info-right {
  padding-left: 50px;
}

/*features-area-five css
===================================================*/
.features-area-five {
  padding: 120px 0px;
  position: relative;
}

.features-area-five .shape-img {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.features-area-five .f-round {
  width: 69px;
  height: 69px;
  border-radius: 50%;
  position: absolute;
  top: 50px;
}

.features-area-five .f-round.circle-one {
  background: #9ce7c0;
  left: 70px;
}

.features-area-five .f-round.circle-two {
  background: #ffe4f1;
  width: 32px;
  height: 32px;
  right: 30px;
}

.features-area-five .feature-five-item {
  text-align: center;
}

.features-area-five .feature-five-item .round {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 83px;
  font-size: 40px;
  color: #ba77ff;
  display: inline-block;
}

/*Custome* CSS*/
.col-md-3.col-sm-6.feature-five-item.style_two {
  text-align: left;
}

.features-area-five .feature-five-item .round2 {
  background: #fff;
  text-align: center;
  font-size: 40px;
  color: #ba77ff;
  display: inline-block;
}

.features-area-six .feature-five-item .round2 {
  color: #557fff;
}

/* Custom Code end */

.features-area-five .feature-five-item h2 {
  font: 500 18px/30px "Poppins", sans-serif;
  color: #404040;

  margin: 35px 0px 15px;
}

.features-area-five .feature-five-item p {
  font: 400 16px/28px "Poppins", sans-serif;
  color: #747474;
}

.features-area-six {
  padding-bottom: 70px;
}

.features-area-six .feature-five-item .round {
  color: #557fff;
}

.feature-area-two {
  background: rgba(151, 136, 255, 0.07);
  padding: 70px 0px 50px;
  position: relative;
  overflow: hidden;
}

.feature-area-two .features_content_three .media .media-left .icon {
  color: #ba77ff;
}

.feature-area-two .round-one {
  width: 565px;
  height: 565px;
  background: #ffcce3;
  opacity: 0.2;
  border-radius: 50%;
  position: absolute;
  right: -282px;
  top: 20%;
}

.feature-area-two .round-two {
  width: 290px;
  height: 290px;
  background: #ffe8b1;
  opacity: 0.5;
  border-radius: 50%;
  position: absolute;
  left: -145px;
  bottom: 12%;
}

.demo-video .app-video {
  vertical-align: middle;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-right: 70px;
}

.demo-video .app-video .title-four h2 {
  font-size: 30px;
  padding-bottom: 18px;
}

.demo-video .app-video p {
  font-size: 18px;
  line-height: 32px;
  color: #585e68;
}

.demo-video .video-promo {
  position: relative;
}

.demo-video .video-promo a {
  width: 110px;
  height: 110px;
  line-height: 110px;
  border-radius: 50%;
  background: #ba77ff;
  opacity: 0.8;
  display: inline-block;
  text-align: center;
  font-size: 25px;
  color: #fff;
  position: absolute;
  top: 48%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 2px solid transparent;
  transition: all 0.3s;
}

/* call-action-area-six css
================================================*/
.call-action-area-five {
  padding: 155px 0px 105px;
  background: rgba(186, 119, 255, 0.88);
}

.call-action-area-six {
  padding: 128px 0px 105px;
}

/*product-features css
==========================================*/
.product-features {
  position: relative;
  background-image: -moz-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -webkit-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -ms-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  z-index: 1;
  padding: 115px 0px 60px;
}

.product-features .title-four h2,
.product-features .title-four p {
  color: #fff;
}

.product-features .title-four .br:before,
.product-features .title-four .br:after {
  background: #fff;
}

.product-features .pr-features-item .single-features {
  position: absolute;
  padding-right: 22px;
  overflow: hidden;
  z-index: 1;
  width: 390px;
  top: 180px;
  left: 80px;
}

.product-features .pr-features-item .single-features h6 {
  font: 400 16px/60px "Poppins", sans-serif;
  text-transform: capitalize;
  color: #fff;
  overflow: hidden;
  position: relative;
}

.product-features .video-bg {
  background: url(../images/home6/pr-features-bg.png) no-repeat scroll center 0 /
    cover;
}

.product-features .pr-features-item .single-features h6:before {
  content: "";
  width: 100%;
  height: 1px;
  background: url(../images/home6/border.png) repeat scroll center;
  display: block;
  top: 50%;
  position: absolute;
  margin-top: -0.5px;
  left: 80px;
}

.product-features .pr-features-item .single-features .dot {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.product-features .pr-features-item .single-features .dot:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
}

.product-features .pr-features-item .single-features .dot:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  -webkit-animation: doublePulse 1.3s ease-out 90ms infinite;
  animation: doublePulse 1.3s ease-out 90ms infinite;
  left: 0;
}

.product-features .pr-features-item .single-features.s_f_two {
  top: 300px;
}

.product-features .pr-features-item .single-features.s_f_three {
  top: 450px;
}

.product-features .pr-features-item .single-features.s_f_five {
  top: 400px;
}

.product-features .pr-features-item .single-features.s_f_four,
.product-features .pr-features-item .single-features.s_f_five {
  padding-left: 22px;
  right: 80px;
  left: auto;
  text-align: right;
  padding-right: 0;
}

.product-features .pr-features-item .single-features.s_f_four h6:before,
.product-features .pr-features-item .single-features.s_f_five h6:before {
  right: 80px;
  left: auto;
}

.product-features .pr-features-item .single-features.s_f_four .dot,
.product-features .pr-features-item .single-features.s_f_five .dot {
  right: auto;
  left: 0;
}

.product-features .pr-features-item.product-features-right {
  padding-left: 0px;
  padding-right: 66px;
  margin-left: -105px;
  margin-right: 0px;
}

.product-features .pr-features-item.product-features-right .single-features {
  padding-left: 22px;
  padding-right: 0;
}

.product-features .pr-features-item.product-features-right .single-features h6 {
  text-align: right;
}

.product-features
  .pr-features-item.product-features-right
  .single-features
  h6:before {
  right: 80px;
  left: auto;
}

.product-features
  .pr-features-item.product-features-right
  .single-features
  .dot {
  right: auto;
  left: 0;
}

@-webkit-keyframes doublePulse {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    opacity: 0.36;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }

  100% {
    opacity: 0;
  }
}

@keyframes doublePulse {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    opacity: 0.36;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }

  100% {
    opacity: 0;
  }
}

/*features_area_seven css
===========================================*/
.features_area_seven {
  padding: 130px 0px 135px;
}

.m_features_item {
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-top: 62px;
}

.m_features_item .icon {
  width: 130px;
  height: 130px;
  display: inline-block;
  font-size: 48px;
  line-height: 130px;
  border-radius: 30px;
  background-color: white;
  -webkit-box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.1);
}

.m_features_item .icon i {
  background-image: -moz-linear-gradient(135deg, #7c6bff 0%, #88e2ff 100%);
  background-image: -webkit-linear-gradient(135deg, #7c6bff 0%, #88e2ff 100%);
  background-image: -ms-linear-gradient(135deg, #7c6bff 0%, #88e2ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.m_features_item h2 {
  font-size: 20px;
  line-height: 40px;

  color: #333333;
  margin: 47px 0px 20px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.m_features_item p {
  font-size: 16px;
  line-height: 28px;
  color: #777777;
}

.m_features_item:hover h2 {
  background-image: -moz-linear-gradient(135deg, #7c6bff 0%, #88e2ff 100%);
  background-image: -webkit-linear-gradient(135deg, #7c6bff 0%, #88e2ff 100%);
  background-image: -ms-linear-gradient(135deg, #7c6bff 0%, #88e2ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*============================ features_area_eight=========================*/
.features_area_five {
  padding: 143px 0px 130px;
}

.features_area_five .features_item {
  text-align: center;
}

.features_area_five .features_item .icon {
  width: 80px;
  height: 80px;
  display: block;
  margin: 0 auto;
  font-size: 24px;
  line-height: 80px;
  color: #24292f;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.features_area_five .features_item .icon:before {
  content: "";
  width: 80px;
  height: 80px;
  border-width: 1px;
  border-color: #f4f4f4;
  border-style: solid;
  background-color: #fcfcfc;
  -webkit-box-shadow: -9.397px -4.58px 0px 0px rgba(3, 36, 73, 0.03);
  box-shadow: -9.397px -4.58px 0px 0px rgba(3, 36, 73, 0.03);
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: -1;
  left: 0;
  border-radius: 15px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.features_area_five .features_item h2 {
  font: 600 22px/45px "Raleway", sans-serif;
  color: #24292f;
  padding: 28px 0px 8px;
}

.features_area_five .features_item p {
  font: 300 14px/24px "Poppins", sans-serif;
  color: #696c70;
}

.features_area_five .features_item:hover .icon {
  color: #fff;
}

.features_area_five .features_item:hover .icon:before {
  background-color: #0270e9;
  -webkit-box-shadow: -9.397px -4.58px 0px 0px rgba(2, 112, 233, 0.14);
  box-shadow: -9.397px -4.58px 0px 0px rgba(2, 112, 233, 0.14);
}

/*============================ featured_area_eight ===========================*/
.featured_area_eight {
  padding: 120px 0px;
}

.featured_content_eight {
  padding-right: 75px;
}

.featured_content_eight h2 {
  font: 300 40px/45px "Raleway", sans-serif;
  color: #24292f;
  padding-bottom: 20px;
}

.featured_content_eight h2 span {
  font-weight: 600;
}

.featured_content_eight p {
  font: 300 14px/24px "Poppins", sans-serif;
  color: #696c70;
}

.featured_eight_info {
  margin-top: -75px;
}

.featured_eight_info .f_eight_item {
  margin-top: 88px;
}

.featured_eight_info .f_eight_item .f_icon {
  width: 75px;
  height: 75px;
  line-height: 72px;
  text-align: center;
  font-size: 20px;
  color: #0270e9;
  border-width: 1px;
  border-color: #cbe2fc;
  border-style: solid;
  background-color: #fcfcfc;
  -webkit-box-shadow: -9.397px 3.42px 0px 0px rgba(3, 36, 73, 0.03);
  box-shadow: -9.397px 3.42px 0px 0px rgba(3, 36, 73, 0.03);
  border-radius: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.featured_eight_info .f_eight_item .f_icon i {
  display: inline-block;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.featured_eight_info .f_eight_item h2 {
  font: 600 20px/28px "Raleway", sans-serif;
  color: #24292f;
  padding: 45px 0px 18px;
}

.featured_eight_info .f_eight_item p {
  color: #696c70;
}

/*================== get_app_eight css ====================*/
.get_app_eight {
  overflow: hidden;
  padding: 77px 0px;
  background: #f9f9f9;
}

.g_app_content h2 {
  font: 600 36px/45px "Raleway", sans-serif;
  color: #24292f;
  padding-bottom: 17px;
}

.g_app_content p {
  color: #696c70;
  padding-bottom: 40px;
}

.get_app_mockup {
  width: auto;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .get_app_mockup {
    position: absolute;
    left: 50px;
  }
}

/*==================== faq_accordian css ========================*/
.faq_area_eight {
  background: #0270e9;
  padding: 120px 0px;
}

.faq_content_inner {
  color: #fff;
}

.faq_content_inner h2 {
  font: 600 36px/40px "Raleway", sans-serif;
  padding-bottom: 17px;
}

.faq_content_inner p {
  font: 300 14px/24px "Poppins", sans-serif;
}

.faq_accordian .card {
  border: 0px;
  border-radius: 0px;
  background: transparent;
}

.faq_accordian .card + .card {
  margin-top: 12px;
}

.faq_accordian .card-header {
  background: transparent;
  border: 0px;
  border-radius: 0px;
  padding: 0px;
  margin-bottom: 4px;
}

.faq_accordian .card-header .btn-link {
  display: block;
  width: 100%;
  text-align: left;
  text-decoration: none;
  font: 500 20px/38px "Raleway", sans-serif;
  color: #fff;
  background-color: #1882f7;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
  padding: 15px 22px 15px 30px;
  border-radius: 0px;
  cursor: pointer !important;
  position: relative;
}

.faq_accordian .card-header .btn-link:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #3f9afe;
  position: absolute;
  right: 80px;
  top: 0;
}

.faq_accordian .card-header .btn-link i {
  float: right;
  display: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #3f9afe;
  text-align: center;
  line-height: 34px;
  font-size: 20px;
}

.faq_accordian .card-header .btn-link i + i {
  display: block;
}

.faq_accordian .card-header .btn-link.collapsed i {
  display: block;
  line-height: 36px;
}

.faq_accordian .card-header .btn-link.collapsed i + i {
  display: none;
}

.faq_accordian .card-body {
  background-color: #1882f7;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
  padding: 25px 30px;
}

.faq_accordian .card-body p {
  font: 300 14px/24px "Poppins", sans-serif;
  color: #fff;
}

/*================ testimonial_area_eight css =================*/
.testimonial_area_eight {
  background: #0270e9;
  padding: 145px 0px;
}

.testimonial_area_eight .testimonial_slider_eight {
  padding: 0px 130px 0px 70px;
  position: relative;
}

.testimonial_area_eight .testimonial_slider_eight .media .media-left {
  padding-right: 30px;
}

.testimonial_area_eight .testimonial_slider_eight .media .media-body {
  color: #fff;
}

.testimonial_area_eight .testimonial_slider_eight .media .media-body p {
  font: 300 14px/24px "Poppins", sans-serif;
  color: #fff;
}

.testimonial_area_eight .testimonial_slider_eight .media .media-body h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-top: 16px;
}

.testimonial_area_eight .testimonial_slider_eight .media .media-body h3 span {
  font: 300 14px/24px "Poppins", sans-serif;
}

.testimonial_area_eight .owl-dots {
  display: inline-block;
  position: absolute;
  top: 50%;
  margin: 0px;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.testimonial_area_eight .owl-dots .owl-dot {
  display: block;
  width: 20px;
  height: 20px;
  border-color: transparent;
  background: transparent;
  position: relative;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.testimonial_area_eight .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: block;
  background: #3d92f0;
  border-radius: 50%;
  top: 50%;
  margin-top: -4px;
  left: 50%;
  margin-left: -4px;
  position: absolute;
}

.testimonial_area_eight .owl-dots .owl-dot.active {
  border-color: #3d92f0;
  background: transparent;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*team_area_solid css
=====================================================================*/
.team_area_solid {
  padding: 120px 0px;
}

.team_member {
  background: #fff;
}

.team_member img {
  max-width: 100%;
}

.team_member .content {
  text-align: center;
  padding: 36px 56px 30px;
}

.team_member .content h2 {
  font: 600 18px/28px "Poppins", sans-serif;
  color: #1a264a;
  text-transform: capitalize;
}

.team_member .content h6 {
  color: #666666;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  padding-bottom: 16px;
}

.team_member .content .social {
  padding-top: 20px;
}

.team_member .content .social a {
  display: inline-block;
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 34px;
  font-size: 14px;
  border-radius: 50%;
  color: #666;
  margin: 0px 3px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.team_member .content .social a:hover {
  background: #4c84ff;
  color: #fff;
}

.team_member:hover h2 {
  color: #4c84ff;
  background-image: none;
}

/* Start Overview-area css
===========================================*/
.testimonial_area {
  padding: 110px 0px 100px;
  overflow: hidden;
}

.testimonial-carousel .testimonial-item {
  margin: 35px 25px 30px;
  padding: 0px 50px 55px;
  -webkit-tap-highlight-color: inherit;
  text-align: center;
  background: #fff;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 50px 0px rgba(26, 38, 74, 0.07);
}

.testimonial-carousel .testimonial-item img {
  width: auto;
  display: inline-block;
}

.testimonial-carousel .testimonial-item .icon {
  background-color: #f8fafc;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(26, 38, 74, 0.15);
  box-shadow: 0px 3px 7px 0px rgba(26, 38, 74, 0.15);
  width: 70px;
  height: 70px;
  line-height: 70px;
  top: -35px;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  text-align: center;
}

.testimonial-carousel .testimonial-item p {
  font: 400 16px/32px "Poppins", sans-serif;
  color: #747474;
  padding-top: 10px;
}

.testimonial-carousel .testimonial-item .media {
  max-width: 310px;
  margin: 20px auto 0px;
  position: relative;
}

.testimonial-carousel .testimonial-item .media .media-left {
  padding-right: 25px;
}

.testimonial-carousel .testimonial-item .media .media-body:before {
  content: "";
  width: 43px;
  background-color: #e1e4fe;
  height: 2px;
  border-radius: 1px;
  position: absolute;
  bottom: 48px;
  right: 43%;
  margin-bottom: 18px;
}

.testimonial-carousel .testimonial-item .media .media-body {
  text-align: center;
  vertical-align: middle;
  padding-top: 22px;
}

.testimonial-carousel .testimonial-item .media .media-body h2 {
  font: 400 14px/32px "Poppins", sans-serif;
  color: #999999;
}

.testimonial-carousel .testimonial-item .media .media-body h6 {
  font: 400 14px/22px "Poppins", sans-serif;
  color: #cccccc;
}

.owl-dots {
  display: block;
  text-align: center;
  margin-top: 30px;
}

.owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #4c84ff;
  display: inline-block;
  margin: 0px 3px;
}

.owl-dots .owl-dot.active {
  background: #4c84ff;
}

/*=========== Start clients-logo-area ==============*/
.clients-logo-area {
  padding: 105px 0px 120px;
}

.clients-lg-slider {
  padding-top: 45px;
}

.clients-lg-slider .item img {
  margin: 0 auto;
  max-width: 100%;
  width: auto;
  opacity: 0.35;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.clients-lg-slider .item:hover img {
  opacity: 1;
}

.clients-lg-slider .owl-dots {
  display: none;
}

/*=========== End clients-logo-area ==============*/
.team_area {
  padding: 142px 0px;
}

.team-slider {
  max-width: 800px;
  margin: 0 auto;
}

.team-slider .item {
  text-align: center;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.team-slider .item .round {
  width: 195px;
  height: 195px;
  border-radius: 50%;
  padding: 6px;
  background: #43308d;
  margin: 0 auto;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
}

.team-slider .item .round img {
  width: auto;
}

.team-slider .item .content {
  opacity: 0;
  visibility: hidden;
  padding-top: 20px;
}

.team-slider .item .content h2 {
  font: 500 20px/30px "Poppins", sans-serif;
  color: #fff;
}

.team-slider .item .content h5 {
  font: 400 14px/26px "Poppins", sans-serif;
  color: #cbcbcb;

  padding-top: 5px;
}

.team-slider .center .item {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.team-slider .center .item .content {
  opacity: 1;
  visibility: visible;
}

.team-slider .owl-dots {
  margin-top: 85px;
}

.team-slider .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #f7c30f;
}

.team-slider .owl-dots .owl-dot.active {
  background: #f7c30f;
}

/*===============================how_work_area css==========================*/
.how_work_area_eight {
  background: #f9f9f9;
  padding: 80px 0px 100px;
  overflow: hidden;
}

.how_work_area_eight .mobile_img {
  position: relative;
}

.how_work_area_eight .mobile_img img {
  max-width: 100%;
}

.how_work_area_eight .mobile_img .img_second {
  position: absolute;
  left: 0;
  top: 100px;
}

.h_work_content_info {
  padding-top: 100px;
}

.h_work_content_info h2 {
  font: 600 40px/45px "Raleway", sans-serif;
  color: #24292f;
}

.h_work_content_info p {
  font: 300 14px/24px "Poppins", sans-serif;
  color: #696c70;
  padding-top: 20px;
}

.h_work_content_info .video_pop {
  font: 600 12px/24px "Raleway", sans-serif;
  color: #898b8e;

  text-decoration: underline;
  padding-top: 35px;
  display: inline-block;
}

.h_work_content_info .video_pop i {
  width: 46px;
  height: 46px;
  background: #fff;
  border: 1px solid #0270e9;
  color: #0270e9;
  text-align: center;
  line-height: 46px;
  border-radius: 50%;
  -webkit-box-shadow: -5px 5px 22px 0px rgba(0, 0, 0, 0.23);
  box-shadow: -5px 5px 22px 0px rgba(0, 0, 0, 0.23);
  margin-right: 15px;
}

/* ==================== team_area_eight css =================== */
.team_area_eight {
  padding: 120px 0px;
}

.team_eight-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.team_eight-item img {
  max-width: 100%;
  -webkit-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;
}

.team_eight-item .hover_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 20px;
  padding: 25px 0px 25px 30px;
  z-index: 1;
  width: 100%;
}

.team_eight-item .hover_content:before {
  content: "";
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: -1;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  background: #0270e9;
}

.team_eight-item .hover_content:after {
  content: "";
  width: 74%;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  position: absolute;
  z-index: -1;
}

.team_eight-item .hover_content .text {
  font-family: "Raleway", sans-serif;
  color: #fff;
}

.team_eight-item .hover_content .text h3 {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

.team_eight-item .hover_content .text h6 {
  font-weight: 300;
}

.team_eight-item .hover_content .icon {
  position: absolute;
  right: 30px;
  -ms-flex-item-align: center;
  align-self: center;
  visibility: hidden;
  opacity: 0;
}

.team_eight-item .hover_content .icon a {
  width: 34px;
  height: 34px;
  border: 1px solid #76b0ef;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  line-height: 34px;
  color: #fff;
}

.team_eight-item:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.team_eight-item:hover .hover_content:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.team_eight-item:hover .hover_content:after {
  background: transparent;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.team_eight-item:hover .hover_content .icon {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 1.2s linear;
  -o-transition: all 1.2s linear;
  transition: all 1.2s linear;
}

.team_eight-item:hover .hover_content .icon a {
  margin: 0px 4px;
}

.team_eight-item:hover .hover_content .icon a:hover {
  background: #fff;
  color: #0270e9;
  border-color: #fff;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.progress_bar_area {
  position: relative;
  background: #000;
  padding: 105px 0px;
  z-index: 1;
}

.progress_bar_area .bcg_parallax {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-attachment: fixed;
}

.progress_bar_area .progress_item {
  color: #fff;
}

.progress_bar_area .progress_item h3 {
  font: 600 50px/65px "Poppins", sans-serif;
}

.progress_bar_area .progress_item h3 i {
  font-size: 44px;
}

.progress_bar_area .progress_item h3 span {
  font-weight: 300;
  padding: 0px 8px;
}

.progress_bar_area .progress_item h6 {
  font: 500 20px/25px "Raleway", sans-serif;
  padding-left: 80px;
}

/* ================== blog_area eight css ================*/
.blog_area_eight {
  padding: 113px 0px 90px;
}

.blog_eight_item img {
  max-width: 100%;
}

.blog_eight_item .blog_content {
  padding-left: 30px;
  padding: 24px 5px 17px 30px;
}

.blog_eight_item .blog_content .date_meta h6 {
  display: inline-block;
  font: 400 14px/20px "Raleway", sans-serif;
}

.blog_eight_item .blog_content .date_meta h6 + h6:before {
  content: "/";
  display: inline-block;
  margin-right: 4px;
}

.blog_eight_item .blog_content h2 {
  font: 600 20px/26px "Raleway", sans-serif;
  color: #24292f;
  padding: 10px 0px 15px;
}

.blog_eight_item .blog_content p {
  color: #696c70;
  padding-bottom: 8px;
}

.blog_eight_item .blog_content a {
  font: 300 12px/40px "Poppins", sans-serif;
  color: #24292f;

  letter-spacing: 0px;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.blog_eight_item .blog_content a i {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.blog_eight_item .blog_content a:hover {
  text-decoration: underline;
  color: #0270e9;
}

.blog_eight_item .blog_content a:hover i {
  margin-left: 5px;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Start Overview-area css
===========================================*/
.overview-area {
  overflow: hidden;
  position: relative;
  padding: 150px 0px 120px;
}

.shape {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.features_area.shape {
  background: #f1f5ff;
}

.shape:before {
  z-index: -1;
  position: absolute;
  right: 0;
  top: 0px;
  content: "";
  display: block;
  background: #fff !important;
  z-index: 0;
  /*
  background-image: -moz-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -webkit-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -ms-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
*/
  opacity: 1;
  min-height: 107px;
  width: 2000px;
  -webkit-transform: rotate(2.5deg);
  -ms-transform: rotate(2.5deg);
  transform: rotate(2.5deg);
  -webkit-transform-origin: 100% 0 0;
  -ms-transform-origin: 100% 0 0;
  transform-origin: 100% 0 0;
}

.whychoose-img img {
  max-width: 100%;
  width: 100%;
}

.overview-details-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.overview-details {
  max-width: 570px;
  margin-right: auto;
  margin-left: 0px;
  padding-left: 70px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.overview-details .title-three {
  margin-bottom: 35px;
}

.overview-details p {
  font-family: "Poppins", sans-serif;
  color: #585e68;
  font-size: 18px;
  line-height: 32px;
  padding-bottom: 42px;
}

/*video_area css
========================================================*/
.video_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.video_area .video-right {
  width: calc(50% + 225px);
}

.video-left {
  width: calc(50% - 225px);
  float: left;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  vertical-align: middle;
  -ms-flex-item-align: center;
  align-self: center;
}

.video-left .video-inner {
  max-width: 710px;
  margin: 0 -200px 0px auto;
  z-index: 2;
  position: relative;
  background: #fff;
  padding: 95px 50px 120px 120px;
  box-shadow: 0px 10px 50px 0px rgba(26, 38, 74, 0.07);
}

.video-left .video-inner .section_title {
  text-align: left;
  margin-bottom: 0px;
}

.video-left .video-inner .section_title p {
  font-size: 16px;
}

.video-left .video-inner p {
  font: 400 18px/32px "Poppins", sans-serif;
  color: #585e68;
}

.play-btn {
  font: 500 14px/14px "Poppins", sans-serif;
  color: #4c84ff;
  letter-spacing: 0px;
  background: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  border-bottom: 1px solid #4c84ff;
  padding: 40px 0px 5px;
  cursor: pointer;
  text-transform: uppercase;
}

.videoWrapper {
  position: relative;
  width: 100%;
  height: 700px;
  background-color: #000;
}

.videoWrapper .videoIframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0px;
}

.videoWrapper .videoPoster {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 0;
  outline: none;
  background-position: 0 50%;
  background-size: cover;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: opacity 800ms, height 0s;
  -moz-transition: opacity 800ms, height 0s;
  -o-transition: opacity 800ms, height 0s;
  transition: opacity 800ms, height 0s;
  -webkit-transition-delay: 0s, 0s;
  -moz-transition-delay: 0s, 0s;
  -o-transition-delay: 0s, 0s;
  transition-delay: 0s, 0s;
  z-index: 1;
}

.videoWrapper .videoPoster:before {
  content: "\e6ad";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  margin: -60px 0 0 -60px;
  border: 5px solid #fff;
  border-radius: 50%;
  font-size: 50px;
  font-family: "themify";
  line-height: 110px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  z-index: 0;
}

.videoWrapper .videoPoster:after {
  position: absolute;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  background-image: -moz-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -webkit-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -ms-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  opacity: 0.8;
  z-index: -1;
}

.videoWrapper.videoWrapperActive .videoPoster {
  opacity: 0;
  -webkit-transition-delay: 0s, 800ms;
  -o-transition-delay: 0s, 800ms;
  transition-delay: 0s, 800ms;
  height: 0;
}

/*analysis_area
=================================================*/
.analysis_area {
  text-align: center;
  padding: 100px 0px 100px 0px;
  position: relative;
  z-index: 1;
}

.analysis_area:before {
  content: "";
  background: url(../images/shape-bg.png) no-repeat scroll center bottom;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: -1;
  left: 0;
}

.analysis_area h2 {
  font: 600 30px/45px "Poppins", sans-serif;
  color: #1a264a;
  margin-bottom: 20px;
}

.apps_button {
  margin-top: 40px;
}

.apps_button a {
  padding: 0 50px;
  height: 50px;
  border: 1px solid #4c84ff;
  color: #4c84ff;
  font: 500 15px/50px "Poppins", sans-serif;
  text-align: center;
  display: inline-block;
  border-radius: 40px;
  margin-right: 20px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.apps_button a i {
  padding-right: 10px;
  font-size: 20px;
}

.apps_button a:hover {
  background: #4c84ff;
  color: #fff;
}

/*analysis-area-two css
=======================================================*/
.analysis_area_two {
  position: relative;
  padding: 110px 0px;
  overflow: hidden;
}

.analysis_area_two:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  background-size: contain;
}

.analysis_area_two .title-six {
  margin-bottom: 100px !important;
}

.analysis_area_two .apps_button {
  text-align: center;
}

.analysis_area_two .apps_button a {
  border-color: #ba77ff;
  color: #ba77ff;
}

.analysis_area_two .apps_button a:hover {
  background: #ba77ff;
  color: #fff;
}

/*========== subcribe_area css ============*/
.gradient-bg {
  background-image: -moz-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -webkit-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -ms-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
}

.subcribe_area {
  padding: 300px 0px 318px;
  position: relative;
  z-index: 0;
}

.subcribe_area .video-bg {
  background: url("../images/call-bg.jpg");
}

.subcribe_area.sec-pad {
  padding: 150px 0px;
}

.video-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: -2;
  opacity: 0.1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.subcribes {
  position: relative;
}

.subcribes.input-group {
  max-width: 630px;
  margin: 0px auto;
  position: relative;
}

.subcribes.input-group .form-control {
  color: #fff;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #fff;
  height: 60px;
  border-radius: 50px;
  width: 100%;
  padding-left: 30px;
}

.subcribes.input-group .form-control.placeholder {
  color: #fff;
}

.subcribes.input-group .form-control:-moz-placeholder {
  color: #fff;
}

.subcribes.input-group .form-control::-moz-placeholder {
  color: #fff;
}

.subcribes.input-group .form-control::-webkit-input-placeholder {
  color: #fff;
}

.subcribes .btn-submit {
  font: 500 16px/32px "Poppins", sans-serif;

  padding: 14px 57px;
  border: 50px;
  position: absolute;
  color: #4c84ff;
  top: 0;
  right: 0;
  border-radius: 50px !important;
  background: #fff;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  z-index: 3 !important;
  cursor: pointer;
}

.subcribes .btn-submit:hover {
  background: #404040;
  color: #fff;
}

.mchimp-errmessage,
.mchimp-sucmessage {
  position: absolute;
  color: #fff;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 500px;
  margin: 0 auto;
  text-align: center;
}

/*=============Start subcribes-area css =================*/
.subcribes-area-four {
  padding: 205px 0px 240px;
  position: relative;
  z-index: 1;
}

.subcribes-area-four .title-five {
  color: #fff;
  margin-bottom: 46px;
  text-align: center;
}

.subcribes-area-four .title-five p {
  padding-top: 0px;
}

.subcribes-area-four .mailchimp .mchimp-errmessage,
.subcribes-area-four .mailchimp .mchimp-sucmessage {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #fff;
  font: 400 14px/24px "Poppins", sans-serif;
}

.subcribes-area-four .plane-img {
  position: absolute;
  bottom: 0px;
  z-index: -1;
  left: 150px;
}

.subcribes-two.input-group {
  max-width: 630px;
  margin: 0px auto;
}

.subcribes-two.input-group .form-control {
  font: 300 16px/40px "Poppins", sans-serif;
  color: #000;
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 60px;
  border: 0px;
  border-radius: 5px;
  padding-left: 30px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.subcribes-two.input-group .form-control.placeholder {
  color: #9fa2a8;
}

.subcribes-two.input-group .form-control:-moz-placeholder {
  color: #9fa2a8;
}

.subcribes-two.input-group .form-control::-moz-placeholder {
  color: #9fa2a8;
}

.subcribes-two.input-group .form-control::-webkit-input-placeholder {
  color: #9fa2a8;
}

.subcribes-two.input-group .input-group-btn .btn-submit {
  font: 600 14px/60px "Poppins", sans-serif;
  color: #fff;
  padding: 0px 62px;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0px;
  background: #f7c30f;

  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  margin: 0px;
  cursor: pointer;
}

.subcribes-two.input-group .input-group-btn .btn-submit:hover {
  background: #6f55d4;
  color: #fff;
}

/*=============End  subcribes-area css =================*/
/*=============Start  Testimonial Area css =================*/
.testimonial-area {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-top: 120px;
}

.testimonial-area:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.testimonial-three:before {
  background: url(http://droitthemes.com/wp/appart/multipage/wp-content/plugins/appart-core/widgets/images/testimonial-bg.png)
    no-repeat center 0 / cover;
}

.testimonial-area .testimonial-img {
  margin-right: -134px;
}

.testimonial-area .testimonial-img .item {
  display: block !important;
}

.testimonial-area .testimonial_slider_five {
  padding-top: 100px;
  margin-bottom: 0px;
}

.testimonial-area .testimonial-content {
  padding-left: 100px;
  position: relative;
}

.testimonial-area .testimonial-content .quote {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
}

.testimonial-area .testimonial-content p {
  font: 400 20px/36px "Open Sans", sans-serif;
  color: #585e68;
  font-style: italic;
  padding-top: 25px;
}

.testimonial-area .testimonial-content .signature {
  display: inline-block;
  margin: 30px 0px 10px;
}

.testimonial-area .testimonial-content h5 {
  font: 500 16px/36px "Poppins", sans-serif;
  color: #999999;
  padding-top: 14px;
}

.testimonial-area .testimonial-content h5 a {
  color: #565b68;
}

.testimonial-area .slick-dots {
  position: relative;
  bottom: 0px;
  width: auto;
  left: 100px;
  text-align: left;
  margin-top: 35px;
}

.testimonial-area .slick-dots li {
  margin: 0px;
}

.testimonial-area .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #d0d0d0;
  padding: 0px;
}

.testimonial-area .slick-dots li button:before {
  display: none;
}

.testimonial-area .slick-dots li.slick-active button {
  background: #ba77ff;
}

/* Start app-deatails-area css
=====================================================*/
.app-deatails-area {
  padding: 180px 0px 0px;
}

.app-deatails-area .title-four {
  margin-bottom: 60px;
}

.app-details {
  position: relative;
  padding: 70px;
  border-right: 1px solid #e8e8f2;
}

.app_info .col-md-6:last-child .app-details {
  border-right: 0px;
}

.app-deatails-area .app_info {
  border-width: 1px;
  border-color: rgb(240, 240, 250);
  border-style: solid;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 30px 80px 0px rgba(0, 0, 29, 0.1);
  position: relative;
  z-index: 1;
}

.app-details:hover .app-icon:before {
  opacity: 1;
}

.app-details:hover .app-icon img {
  -webkit-filter: brightness(3);
  filter: brightness(3);
}

.app-details .app-icon {
  width: 80px;
  height: 80px;
  line-height: 78px;
  border: 1px solid #4c84ff;
  text-align: center;
  border-radius: 3px;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.app-details .app-icon:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-image: -moz-linear-gradient(
    180deg,
    rgb(0, 186, 222) 0%,
    rgb(74, 119, 244) 100%
  );
  background-image: -webkit-linear-gradient(
    180deg,
    rgb(0, 186, 222) 0%,
    rgb(74, 119, 244) 100%
  );
  background-image: -ms-linear-gradient(
    180deg,
    rgb(0, 186, 222) 0%,
    rgb(74, 119, 244) 100%
  );
  background-image: linear-gradient(
    180deg,
    rgb(0, 186, 222) 0%,
    rgb(74, 119, 244) 100%
  );
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 29, 0.14);
  z-index: -1;
  opacity: 0;
  transition: all 0.3s linear;
}

.app-details .app-icon img {
  display: inline-block;
}

/*
.app-details .app-icon:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  z-index: -1;
  opacity: 1;
}
*/

.app-details .app-title {
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-bottom: 35px;
}

.app-details .app-title h5 {
  font: 600 20px/30px "Poppins", sans-serif;
  color: #282835;
  letter-spacing: 0px;
  padding: 45px 0px 10px;
}

.app-details .user-info {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #8b8b99;
}

.app-details .user-info .star-rating {
  display: inline-block;
  color: #f8bf10;
  font-size: 12px;
}

.app-details .customer_table table {
  margin-bottom: 0px;
}

.app-details .customer_table table td {
  border: 0px;
  font: 600 16px/22px "Poppins", sans-serif;
  color: #282835;
  padding: 10px 0px;
}

.app-details .customer_table table td + td {
  font-weight: 500;
  color: #585e68;
}

/*

@-webkit-keyframes pluse {
  0% {
    opacity: .3;
  }
  40% {
    opacity: .5;
    -webkit-box-shadow: 0 0 0 2px rgba(241, 245, 255, 0.1), 0 0 10px 10px #f1f5ff, 0 0 0 10px rgba(241, 245, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(76, 131, 255, 0.1), 0 0 10px 10px #f1f5ff, 0 0 0 10px rgba(76, 131, 255, 0.5);
  }
  80% {
    -webkit-box-shadow: 0 0 0 5px rgba(241, 245, 255, 0.1), 0 0 40px 40px #f1f5ff, 0 0 0 40px rgba(241, 245, 255, 0.5);
    box-shadow: 0 0 0 5px rgba(241, 245, 255, 0.1), 0 0 40px 40px #f1f5ff, 0 0 0 40px rgba(241, 245, 255, 0.5);
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  100% {
    -webkit-box-shadow: 0 0 0 2px rgba(241, 245, 255, 0.1), 0 0 10px 10px #f1f5ff, 0 0 0 10px rgba(241, 245, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(241, 245, 255, 0.1), 0 0 10px 10px #f1f5ff, 0 0 0 10px rgba(241, 245, 255, 0.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes pluse {
  0% {
    opacity: .3;
  }
  40% {
    opacity: .5;
    -webkit-box-shadow: 0 0 0 2px rgba(241, 245, 255, 0.1), 0 0 10px 10px #f1f5ff, 0 0 0 10px rgba(241, 245, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(76, 131, 255, 0.1), 0 0 10px 10px #f1f5ff, 0 0 0 10px rgba(76, 131, 255, 0.5);
  }
  80% {
    -webkit-box-shadow: 0 0 0 5px rgba(241, 245, 255, 0.1), 0 0 40px 40px #f1f5ff, 0 0 0 40px rgba(241, 245, 255, 0.5);
    box-shadow: 0 0 0 5px rgba(241, 245, 255, 0.1), 0 0 40px 40px #f1f5ff, 0 0 0 40px rgba(241, 245, 255, 0.5);
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  100% {
    -webkit-box-shadow: 0 0 0 2px rgba(241, 245, 255, 0.1), 0 0 10px 10px #f1f5ff, 0 0 0 10px rgba(241, 245, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(241, 245, 255, 0.1), 0 0 10px 10px #f1f5ff, 0 0 0 10px rgba(241, 245, 255, 0.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
*/

/*about_content_area css
=====================================*/
/*----------------------------------------------------*/
.about_banner_area {
  padding-top: 240px;
  padding-right: 0;
  padding-bottom: 187px;
  padding-left: 0;
  background: url("../images/banner/appart-post.jpg") no-repeat scroll center 0 /
    cover;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #fff;
}

.about_banner_area:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: -1;
}

.about_banner_area .banner-content {
  margin-left: auto;
  margin-right: auto;
}

.about_banner_area .banner-content h1 {
  font: 500 50px/55px "Poppins", sans-serif;
  letter-spacing: 1.2px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  padding-bottom: 8px;
}

.about_banner_area p {
  font: 300 16px/40px "Poppins", sans-serif;
}

.feature_banner_area {
  background: url("../images/banner/features_bg.jpg") no-repeat scroll center 0 /
    cover;
}

.pricing_banner_area {
  background: url("../images/banner/pricing.png") no-repeat scroll center 0 /
    cover;
}

.blog_banner_area {
  background: url("../images/banner/call-action-bg-1.jpg") no-repeat scroll
    center 0 / cover;
}

.faq_banner_area {
  background: url("../images/banner/get_bg.jpg") no-repeat scroll center 0 /
    cover;
}

.team_banner_area {
  background: url("../images/banner/call-bg-1.jpg") no-repeat scroll center 0 /
    cover;
}

.contact_banner_area {
  background: url("../images/banner/contact.jpg") no-repeat scroll center 0 /
    cover;
}

.testimonial_banner_area {
  background: url("../images/banner/testimonial_bg.jpg") no-repeat scroll center
    0 / cover;
}

.about_content_two {
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.about_content_two h6 {
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 1.6px;

  color: #666666;
  margin-top: 27px;
}

.about_content_two h2 {
  font-size: 30px;
  line-height: 35px;
  color: #333333;
  padding: 24px 0px 14px;
}

.about_content_two p {
  font-size: 16px;
  line-height: 28px;
  color: #777;
  max-width: 970px;
  margin: 25px auto;
}

/* Start video_area css
=====================================*/
.video_area_two {
  position: relative;
  z-index: 1;
  padding: 140px 0px;
}

.video_area_two:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: -moz-linear-gradient(135deg, #7c6bff 0%, #88e2ff 100%);
  background-image: -webkit-linear-gradient(135deg, #7c6bff 0%, #88e2ff 100%);
  background-image: -ms-linear-gradient(135deg, #7c6bff 0%, #88e2ff 100%);
  opacity: 0.902;
  position: absolute;
  z-index: -1;
}

.video-icon {
  width: 200px;
  height: 200px;
  border: 1px solid #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 208px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video-icon:before {
  content: "";
  width: 165px;
  height: 165px;
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video-icon i {
  width: 130px;
  height: 130px;
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 130px;
  font-size: 35px;
  color: #7f8cff;
  position: relative;
}

.video-icon i:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  border: 1.5px solid #fff;
  position: absolute;
  left: 0;
  -webkit-animation: doublePulse2 1.3s ease-out 90ms infinite;
  animation: doublePulse2 1.3s ease-out 90ms infinite;
  z-index: -1;
}

.video-contents h3 {
  font: 300 40px/55px "Poppins", sans-serif;
  letter-spacing: 0;
  color: #fff;
  font-style: italic;
}

.video-contents .media {
  margin-top: 25px;
}

.video-contents .media img {
  border: 1px solid #fff;
  border-radius: 50%;
  background: #fff;
}

.video-contents .media .media-body {
  color: #fff;
}

.video-contents .media .media-body h5 {
  font: 700 24px/40px "Poppins", sans-serif;
}

.video-contents .media .media-body p {
  font: 400 18px/25px "Poppins", sans-serif;
}

/* Start subscribe_area css
====================================*/
.subscribe_area {
  padding: 130px 0px 135px;
  position: relative;
  z-index: 1;
}

.subscribe_area:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
}

.subscribe_info .social_icon li a {
  width: 45px;
  height: 45px;
  border-radius: 15px;
  background: #c6c6c6;
  font-size: 20px;
  line-height: 45px;
  color: #fff;
  display: inline-block;
  text-align: center;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  margin: 0px 10px;
  -webkit-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}

.subscribe_info .social_icon li a:hover {
  background-image: -moz-linear-gradient(180deg, #85c6ff 0%, #7f8cff 100%);
  background-image: -webkit-linear-gradient(180deg, #85c6ff 0%, #7f8cff 100%);
  background-image: -ms-linear-gradient(180deg, #85c6ff 0%, #7f8cff 100%);
}

.subscribe_info .sub_content {
  padding-top: 140px;
}

.subscribe_info .sub_content p {
  color: #777;
  padding-top: 40px;
}

.subscription {
  max-width: 630px;
  margin: 50px auto;
  position: relative;
}

.subscription input {
  width: 70%;
  margin-right: 20px;
  font: 400 16px/35px "Poppins", sans-serif;
  color: #aaaaaa;
  background: #fff;
  padding: 0px 25px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  border: 0px;
  outline: none;
  height: 50px;
}

.subscription .subcribe-btn {
  font: 700 14px/36px "Poppins", sans-serif;
  letter-spacing: 1.4px;
  padding: 7px 46px;
  border-radius: 3px;

  color: #fff;
  border: 0px;
  background-image: -moz-linear-gradient(10deg, #85c6ff 0%, #7f8cff 100%);
  background-image: -webkit-linear-gradient(-150deg, #85c6ff 0%, #7f8cff 100%);
  background-image: -ms-linear-gradient(180deg, #85c6ff 0%, #7f8cff 100%);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  background-size: 200% auto;
}

.subscription .subcribe-btn:hover {
  background-position: right center;
}

.subscription .mchimp-errmessage,
.subscription .mchimp-sucmessage {
  bottom: -33px;
  color: #666;
}

@-webkit-keyframes doublePulse2 {
  0% {
    opacity: 0.3;
  }

  40% {
    opacity: 0.5;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  40% {
    opacity: 0.36;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
  }
}

@keyframes doublePulse2 {
  0% {
    opacity: 0.3;
  }

  40% {
    opacity: 0.5;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  40% {
    opacity: 0.36;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
  }
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*===================== contact_area css ======================*/
.contact_area {
  background: #f9f9ff;
}

.contact_area h2 {
  font: 700 22px/30px "Raleway", sans-serif;
  color: #24292f;
  padding-bottom: 20px;
}

.contact_area p {
  font: 400 15px/24px "Raleway", sans-serif;
  color: #898c90;
}

.contact_inner {
  border: 1px solid #f3f3f3;
  background-color: white;
  -webkit-box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.14);
  padding: 53px 40px 40px 40px;
  margin-bottom: -250px;
  z-index: 2;
  position: relative;
}

.contact_info .contact-form .form-group {
  margin-bottom: 20px;
}

.contact_info .contact-form .form-group .form-control {
  font: 300 14px/30px "Poppins", sans-serif;
  color: #77797b;
  padding: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #f9f9f9;
  border: 1px solid transparent;
  height: 64px;
  padding-left: 30px;
}

.contact_info .contact-form .form-group .form-control.placeholder {
  color: #77797b;
}

.contact_info .contact-form .form-group .form-control:-moz-placeholder {
  color: #77797b;
}

.contact_info .contact-form .form-group .form-control::-moz-placeholder {
  color: #77797b;
}

.contact_info
  .contact-form
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #77797b;
}

.contact_info .contact-form .form-group .form-control:focus {
  border-color: #e5eff9;
  background-color: white;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 28, 59, 0.08);
  box-shadow: 0px 10px 20px 0px rgba(0, 28, 59, 0.08);
}

.contact_info .contact-form .form-group .form-control.error + .error {
  font-size: 12px;
  margin-bottom: 0px;
}

.contact_info .contact-form .form-group textarea.form-control {
  height: 150px;
  padding-top: 17px;
}

.contact_info .contact-form .form-group .thm-btn {
  font: 600 12px/30px "Raleway", sans-serif;
  color: #fff;

  letter-spacing: 3.6px;
  background: #0270e9;
  padding: 9px 41px;
  border-radius: 2px;
  margin-top: 10px;
}

.get_info {
  padding: 0px 0px 0px 70px;
}

.get_info p {
  padding-bottom: 35px;
}

.get_info .media {
  margin-bottom: 40px;
}

.get_info .media .icon {
  width: 55px;
  height: 55px;
  background-color: #0f78eb;
  -webkit-box-shadow: -6px 8px 0px 0px rgba(15, 120, 235, 0.14);
  box-shadow: -6px 8px 0px 0px rgba(15, 120, 235, 0.14);
  font-size: 18px;
  line-height: 55px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

.get_info .media .icon i {
  display: inline-block;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.get_info .media .media-body {
  vertical-align: middle;
  padding-left: 35px;
}

.get_info .media .media-body h3 {
  font: 600 16px/24px "Raleway", sans-serif;
  color: #24292f;
}

.get_info .media .media-body h5 {
  font: 400 15px/24px "Raleway", sans-serif;
  color: #676b71;
}

.get_info .media .media-body h5 a {
  color: #676b71;
}

.map #mapBox {
  height: 450px;
}

.contact-form #success,
.contact-form #error {
  display: none;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.screenshot_area_two {
  padding: 150px 0px 25px;
}

.screenshot_area_two .section_title_three {
  margin-bottom: 55px;
}

.screenshot-btn {
  font: 700 14px/21px "Poppins", sans-serif;
  letter-spacing: 0.42px;
  color: #fff;
  background: #a661ef;
  padding: 15px 55px;
  border-radius: 3px;
  display: inline-block;

  margin-top: 50px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.screenshot-btn:hover {
  background: #9f50f3;
  color: #fff;
}

.swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-bottom: 100px;
}

/*.swiper-container .swiper-wrapper .swiper-slide {
  -webkit-box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 0.2);
}*/

.swiper-container .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border: 1px solid #a661ef;
  background: transparent;
}

.swiper-container
  .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #a661ef;
}

/*app-promo css
===========================================================*/
.app-promo {
  background-image: -moz-linear-gradient(0deg, #7464fd 0%, #5851ea 40%);
  background-image: -webkit-linear-gradient(0deg, #7464fd 0%, #5851ea 40%);
  background-image: -ms-linear-gradient(0deg, #7464fd 0%, #5851ea 40%);
  padding: 120px 0px;
}

.app-promo-content {
  padding-top: 105px;
}

.app-promo-content h2 {
  font: 500 40px/54px "Poppins", sans-serif;
  color: #fff;
  margin-bottom: 50px;
}

.app-promo-content .promo-item {
  position: relative;
  font: 400 16px/28px "Poppins", sans-serif;
  color: #fff;
  padding-left: 65px;
  padding-bottom: 28px;
}

.app-promo-content .promo-item span {
  font-weight: 600;
}

.app-promo-content .promo-item i {
  width: 46px;
  height: 46px;
  border: 3px solid #fff;
  font-size: 19px;
  line-height: 42px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 0;
}

.promo-button {
  margin-top: 30px;
  overflow: hidden;
}

.promo-button .banner_btn {
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #4c84ff;
  letter-spacing: 0;
  width: 180px;
  height: 50px;
  line-height: 49px;
}

.promo-button .banner_btn:hover {
  color: #fff;
}

.promo-button .learn-btn {
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-decoration: underline;
  font-size: 15px;
  font-weight: 600;
  margin-left: 25px;
}

.app-promo-img {
  position: relative;
  max-width: 540px;
  margin-right: 0;
  margin-left: auto;
}

.app-promo-img .img1 {
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 1;
}

.app-promo-img .promo-img {
  border-radius: 5px;
  background-color: #daa932;
  -webkit-box-shadow: 0px 5px 25px 0px rgba(5, 5, 5, 0.2);
  box-shadow: 0px 5px 25px 0px rgba(5, 5, 5, 0.2);
}

.app-promo-img .img2 {
  position: absolute;
  right: 0;
  top: 40px;
}

.app-promo-img .img3 {
  z-index: 1;
  position: relative;
  margin-left: 95px;
  margin-top: 80px;
}

.screenshot_area_three {
  background: #f8f8f8;
  padding: 135px 0px 50px;
}

.screenshot_area_three .section_title_two {
  margin-bottom: 70px;
}

.ecommerce_area {
  padding: 160px 0px;
}

.ecommerce_area .app-promo-content-two h2 {
  color: #363b3e;
}

.ecommerce_area .app-promo-content-two .promo-item {
  color: #363b3e;
  padding-right: 155px;
}

.ecommerce_area .app-promo-content-two .promo-item i {
  border-color: #bec2c3;
  color: #363b3e;
}

.ecommerce_area .app-promo-content-two .promo-button .banner_btn {
  background: #2990ea;
  border: transparent;
  color: #fff;
}

.ecommerce_area .app-promo-content-two .promo-button .banner_btn:hover {
  background: #0c7cde;
}

.ecommerce_area .app-promo-content-two .promo-button .learn-btn {
  color: #2990ea;
}

.ecommerce_area .ecommerce-screen {
  position: relative;
  padding-right: 20px;
  position: relative;
}

.ecommerce_area .ecommerce-screen img {
  -webkit-box-shadow: 0px 0px 45px 9px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 45px 9px rgba(0, 0, 0, 0.2);
}

.ecommerce_area .ecommerce-screen .ec_img_two {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 80px;
  z-index: -1;
}

.app-promo-content-two h2 {
  font-size: 46px;
  line-height: 62px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.app-promo-content-two .promo-item {
  font-size: 18px;
  font-family: "Lato", sans-serif;
}

/*price_area_seven css
=============================================*/
.price_area_seven {
  padding: 130px 0px 140px;
}

.price_info {
  width: 1020px;
  margin: 70px auto 0px;
}

.price_info .price_item {
  padding: 40px 0px 50px;
  text-align: center;
  background: #f6fcfd;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
}

.price_info .price_item .icon {
  width: 120px;
  height: 120px;
  border-radius: 30px;
  background: #fff;
  text-align: center;
  line-height: 120px;
  display: inline-block;
}

.price_info .price_item .p_title {
  font-size: 20px;
  line-height: 35px;
  color: #333333;
  font-weight: 700;
  margin: 30px 0px 5px;
}

.price_info .price_item ul {
  min-height: 170px;
}

.price_info .price_item ul li {
  font-size: 14px;
  line-height: 40px;
  color: #777777;
}

.price_info .price_item h3 {
  font-size: 40px;
  color: #656ff1;
  margin-bottom: 26px;
}

.price_info .price_item h3 sup,
.price_info .price_item h3 sub {
  font-size: 12px;
}

.price_info .price_item h3 sup {
  top: -18px;
}

.price_info .price_item .price_btn {
  font: 700 14px/35px "Poppins", sans-serif;

  color: #656ff1;
  padding: 6px 44px;
  border: 1px solid #656ff1;
  border-radius: 2px;
  display: inline-block;
  -webkit-transform: all 0.9s linear;
  -ms-transform: all 0.9s linear;
  transform: all 0.9s linear;
  position: relative;
  z-index: 1;
}

.price_info .price_item .price_btn:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: -moz-linear-gradient(-90deg, #85c6ff 0%, #7f8cff 100%);
  background-image: -webkit-linear-gradient(-90deg, #85c6ff 0%, #7f8cff 100%);
  background-image: -ms-linear-gradient(-90deg, #85c6ff 0%, #7f8cff 100%);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.price_info .price_item .price_btn:hover {
  border-color: transparent;
  color: #fff;
}

.price_info .price_item .price_btn:hover:before {
  opacity: 1;
}

.price_info .price_item.active {
  background: #fff;
  -webkit-box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
}

.price_info .price_item.active .icon {
  background: #f6fcfd;
}

.price_info .price_item.active .price_btn {
  background-image: -moz-linear-gradient(-90deg, #85c6ff 0%, #7f8cff 100%);
  background-image: -webkit-linear-gradient(-90deg, #85c6ff 0%, #7f8cff 100%);
  background-image: -ms-linear-gradient(-90deg, #85c6ff 0%, #7f8cff 100%);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  border: 0px;
  color: #fff;
}

/* Start get_app_area_seven css
=========================================*/
.get_app_area_seven {
  position: relative;
  background-attachment: fixed;
  z-index: 1;
  padding: 230px 0px;
}

.get_app_area_seven:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: -moz-linear-gradient(-30deg, #7c6bff 0%, #88e2ff 100%);
  background-image: -webkit-linear-gradient(-30deg, #7c6bff 0%, #88e2ff 100%);
  background-image: -ms-linear-gradient(-30deg, #7c6bff 0%, #88e2ff 100%);
  opacity: 0.902;
  position: absolute;
  z-index: -1;
}

.get_app_area_seven:after {
  content: "";
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-size: contain;
  z-index: -1;
}

.get_app-content h2 {
  font: 300 40px/45px "Poppins", sans-serif;
  color: #fff;
  margin-bottom: 65px;
}

.get_app-content a {
  display: inline-block;
  font: 400 24px/35px "Poppins", sans-serif;
  color: #000;
  padding: 33px 40px 33px 120px;
  background: #fff;
  -webkit-box-shadow: 0px 10px 79px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 79px 0px rgba(0, 0, 0, 0.2);
  position: relative;
}

.get_app-content a i {
  width: 60px;
  height: 60px;
  background: #c6c6c6;
  text-align: center;
  color: #fff;
  line-height: 60px;
  position: absolute;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.get_app-content a + a {
  margin-left: 25px;
}

.get_app-content a.google-play i {
  background: #5cdadd;
}

/*screenshot_area css
=========================================*/
.screenshot_area {
  overflow: hidden;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 130px 0px 138px;
}

.screenshot_area:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: -moz-linear-gradient(0deg, #ce94fb 0%, #aa5cf8 100%);
  background-image: -webkit-linear-gradient(0deg, #ce94fb 0%, #aa5cf8 100%);
  background-image: -ms-linear-gradient(0deg, #ce94fb 0%, #aa5cf8 100%);
  opacity: 0.85;
  position: absolute;
  z-index: -1;
}

.screenshot_area .sec_center {
  overflow: hidden;
  display: block;
  margin-bottom: 70px;
}

.screenshot_area .sec_center h2,
.screenshot_area .sec_center h5 {
  color: #fff;
}

.screenshot_left {
  width: calc(50% + 10px);
  float: left;
}

.screenshot_left .screenshot_carousel {
  padding-top: 77px;
  padding-bottom: 77px;
}

.screenshot_left .screenshot_carousel:before {
  position: absolute;
  content: "";
  background-position: 50% 0;
  background-repeat: no-repeat;
  height: 100%;
  width: 317px;
  z-index: 0;
  right: -19px;
  top: 0px;
  background-size: contain;
}

.screenshot_right {
  width: calc(50% - 10px);
  float: left;
  padding-left: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.screenshot_right .screenshot_content {
  max-width: 470px;
  margin-left: 0;
  margin-right: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  vertical-align: middle;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

.screenshot_right .screenshot_content h2 {
  font-size: 34px;
  line-height: 35px;
  font-weight: 300;
}

.screenshot_right .screenshot_content p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  padding: 30px 0px;
}

.home_screen_nav i {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  font-size: 16px;
  line-height: 50px;
  display: inline-block;
}

/*==================screenshot_area_eight css ==================*/
.screenshot_area_eight {
  padding: 120px 0px 100px;
}

.screenshot_area_eight .section_title_eight {
  margin-bottom: 30px;
}

.screenshot_area_eight .swiper-container {
  max-width: 850px;
  margin: 0 auto;
}

.screenshot_area_eight .swiper-container .color-p .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  border: 1px solid transparent;
  position: relative;
  background: transparent;
  opacity: 1;
  margin: 0px;
}

.screenshot_area_eight
  .swiper-container
  .color-p
  .swiper-pagination-bullet:before {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  position: absolute;
  content: "";
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #bcd5f0;
}

.screenshot_area_eight
  .swiper-container
  .color-p
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: #0270e9;
}

.screenshot_area_eight
  .swiper-container
  .color-p
  .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  background: #0270e9;
}

/*======================= price-area_eight css ==========================*/
.price-area_eight {
  padding: 120px 0px;
}

.price-info_eight .price_item_eight {
  text-align: center;
  background: #f8f8f8;
  color: #24292f;
  padding: 50px 0px;
  border: 1px solid transparent;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.price-info_eight .price_item_eight .p_head {
  font: 600 26px "Raleway", sans-serif;
}

.price-info_eight .price_item_eight .tag {
  position: absolute;
  top: -8px;
  right: -28px;
  background: #0270e9;
  color: #fff;
  padding: 13px 27px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-box-shadow: 0.397px 6.42px 0px 0px rgba(3, 36, 73, 0.05);
  box-shadow: 0.397px 6.42px 0px 0px rgba(3, 36, 73, 0.05);
}

.price-info_eight .price_item_eight .tag h6 {
  font: 500 12px/18px "Raleway", sans-serif;
  position: relative;
  top: 5px;
}

.price-info_eight .price_item_eight .price {
  font: 500 60px/60px "Raleway", sans-serif;
  color: #24292f;
  padding: 18px 0px 40px;
}

.price-info_eight .price_item_eight .price span {
  font-weight: 600;
  font-size: 16px;
  vertical-align: top;
  line-height: 60px;
}

.price-info_eight .price_item_eight .price small {
  font-weight: 400;
  font-size: 16px;
  vertical-align: bottom;
  line-height: 20px;
}

.price-info_eight .price_item_eight ul li {
  font: 400 14px/30px "Raleway", sans-serif;
  color: #6b6f74;
}

.price-info_eight .price_item_eight .price_btn_three {
  font: 600 12px/23px "Raleway", sans-serif;
  color: #0270e9;
  padding: 10px 26px 9px;
  border-width: 1px;
  border-color: #c6ddf6;
  border-style: solid;
  border-radius: 2px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  display: inline-block;

  margin-top: 34px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.price-info_eight .price_item_eight.current,
.price-info_eight .price_item_eight:hover {
  background-color: white;
  border-color: #f3f3f3;
  -webkit-box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.1);
}

.price-info_eight .price_item_eight.current .price_btn_three,
.price-info_eight .price_item_eight:hover .price_btn_three {
  background: #0270e9;
  color: #fff;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============= Start support area css =================*/
.footer-top {
  background: #2a2a2a;
  padding: 118px 0px;
}

.footer-top .footer_sidebar .widget {
  font: 400 16px/28px "Poppins", sans-serif;
  color: rgba(255, 255, 255, 0.5);
}

.footer-top .footer_sidebar .widget .logo {
  padding-bottom: 15px;
  display: block;
}

.footer-top .footer_sidebar .widget .logo .footer-logo {
  height: 50px;
}

.footer-top .footer_sidebar .widget .widget_title {
  font: 700 16px/21px "Poppins", sans-serif;
  color: #fff;
  letter-spacing: 0.8px;

  padding-bottom: 33px;
}

.footer-top .footer_sidebar .widget.about_us_widget .social_icon {
  padding-top: 10px;
}

.footer-top .footer_sidebar .widget.about_us_widget .social_icon li {
  display: inline-block;
}

.footer-top .footer_sidebar .widget.about_us_widget .social_icon li a {
  font-size: 14px;
  padding: 0px 0px 0px 18px;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  background: transparent;
}

.footer-top .footer_sidebar .widget.about_us_widget .social_icon li a i {
  color: #fff;
  opacity: 0.5;
}

.footer-top .footer_sidebar .widget.about_us_widget .social_icon li:hover i {
  color: #4c84ff;
  opacity: 1;
}

.footer-top
  .footer_sidebar
  .widget.about_us_widget
  .social_icon
  li:first-child
  a {
  padding-left: 0px;
}

.footer-top .footer_sidebar .widget.widget_contact ul li {
  display: table;
  width: 100%;
}

.footer-top .footer_sidebar .widget.widget_contact ul li i {
  float: left;
  font-size: 14px;
  color: #fff;
  opacity: 0.3;
  line-height: 30px;
  width: 30px;
}

.footer-top .footer_sidebar .widget.widget_contact ul li .fleft {
  float: left;
  width: calc(100% - 30px);
}

.footer-top .footer_sidebar .widget.widget_contact ul li .fleft a {
  color: #fff;
  opacity: 0.5;
}

.footer-top .footer_sidebar .widget.widget_contact ul li .fleft a:hover {
  color: #4c84ff;
  opacity: 1;
}

.footer-top .footer_sidebar .widget.widget_contact ul li + li {
  padding-top: 18px;
}

.footer-top .footer_sidebar .widget.widget_twitter {
  padding-right: 50px;
}

.footer-top .footer_sidebar .widget.widget_twitter .tweets li {
  width: 100%;
  display: table;
}

.footer-top .footer_sidebar .widget.widget_twitter .tweets li i {
  float: left;
  width: 35px;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  opacity: 0.2;
}

.footer-top .footer_sidebar .widget.widget_twitter .tweets li .tweets-text {
  float: left;
  width: calc(100% - 35px);
}

.footer-top .footer_sidebar .widget.widget_twitter .tweets li .tweets-text a {
  opacity: 1;
  color: #fff;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.footer-top
  .footer_sidebar
  .widget.widget_twitter
  .tweets
  li
  .tweets-text
  a:hover {
  color: #4c84ff;
}

.footer-top .footer_sidebar .widget.widget_twitter .tweets li + li {
  padding-top: 25px;
}

.footer-top .footer_sidebar .widget.widget_instagram {
  overflow: hidden;
}

.footer-top .footer_sidebar .widget.widget_instagram .instagram-feed {
  margin-left: -3px;
  margin-right: -3px;
  overflow: hidden;
  max-width: 270px;
}

.footer-top .footer_sidebar .widget.widget_instagram .instagram-feed li {
  float: left;
  padding: 3px;
  width: calc(100% / 3);
}

.footer-top .footer_sidebar .widget.widget_instagram .instagram-feed li a {
  display: block;
}

.footer-top .footer_sidebar .widget.widget_instagram .instagram-feed li a img {
  max-width: 100%;
}

.footer-top .footer_sidebar .widget.widget_instagram span {
  font-size: 14px;
  letter-spacing: 0.2px;
  padding-top: 17px;
  display: block;
  width: 100%;
}

.footer-top .footer_sidebar .widget.widget_instagram span a {
  color: #fff;
}

.footer-top .footer_sidebar .widget.widget_instagram span a:hover {
  color: #4c84ff;
}

.footer_bottom {
  background: #242424;
  font: 400 16px/20px "Poppins", sans-serif;
  color: rgba(255, 255, 255, 0.3);
  padding: 30px 0px;
}

.footer_bottom a {
  color: #fff;
}

.footer_bottom a:hover {
  color: #4c84ff;
  opacity: 1;
}

.footer_bottom .footer-menu {
  width: 100%;
  text-align: right;
}

.footer_bottom .footer-menu li {
  display: inline-block;
}

.footer_bottom .footer-menu li + li {
  padding-left: 38px;
}

/*============= End footer area css =================*/
.footer-four
  .footer-top
  .footer_sidebar
  .widget.about_us_widget
  .social_icon
  li:hover
  i,
.footer-four
  .footer-top
  .footer_sidebar
  .widget.widget_contact
  ul
  li
  .fleft
  a:hover,
.footer-four
  .footer-top
  .footer_sidebar
  .widget.widget_twitter
  .tweets
  li
  .tweets-text
  a:hover,
.footer-four .footer-top .footer_sidebar .widget.widget_instagram span a:hover {
  color: #6f55d4;
}

.footer-four .footer_bottom a:hover {
  color: #6f55d4;
}

.footer-five .footer-top {
  background: #f9f8ff;
}

.footer-five .footer-top .footer_sidebar .widget {
  font: 400 15px/28px "Poppins", sans-serif;
  color: #585e68;
}

.footer-five .footer-top .footer_sidebar .widget .widget_title_two {
  font: 600 18px/18px "Poppins", sans-serif;
  color: #333333;
  position: relative;
  padding-bottom: 18px;
  margin-bottom: 25px;
}

.footer-five .footer-top .footer_sidebar .widget.widget_contact ul li i {
  color: #585e68;
  opacity: 1;
}

.footer-five .footer-top .footer_sidebar .widget.widget_contact ul li .fleft a {
  color: #585e68;
  opacity: 1;
}

.footer-five
  .footer-top
  .footer_sidebar
  .widget.widget_contact
  ul
  li
  .fleft
  a:hover {
  color: #ba77ff;
}

.footer-five .footer-top .footer_sidebar .widget.widget_link .link li a {
  font: 400 15px/18px "Poppins", sans-serif;
  letter-spacing: 0.3px;
  color: #585e68;
  text-transform: capitalize;
  padding-bottom: 15px;
  display: inline-block;
}

.footer-five .footer-top .footer_sidebar .widget.widget_link .link li a:hover {
  color: #ba77ff;
}

.footer-five .footer-top .footer_sidebar .widget.widget_social {
  padding-left: 60px;
}

.footer-five
  .footer-top
  .footer_sidebar
  .widget.widget_social
  .social-icon
  li
  a {
  font: 400 15px/18px "Poppins", sans-serif;
  letter-spacing: 0.3px;
  color: #585e68;
  display: inline-block;
  padding-bottom: 15px;
}

.footer-five
  .footer-top
  .footer_sidebar
  .widget.widget_social
  .social-icon
  li
  a:hover {
  color: #ba77ff;
}

.footer-five
  .footer-top
  .footer_sidebar
  .widget.widget_social
  .social-icon
  li
  a
  i {
  padding-right: 20px;
}

.footer-five .footer_bottom {
  background: #f3f1fb;
  color: rgba(88, 94, 104, 0.8);
}

.footer-five .footer_bottom a {
  color: #585e68;
}

.footer-five .footer_bottom a:hover {
  color: #ba77ff;
  opacity: 1;
}

.footer-five-color .widget.widget_contact ul li .fleft a:hover,
.footer-five-color .widget.about_us_widget .social_icon li:hover i,
.footer-five-color .widget.widget_twitter .tweets li .tweets-text a:hover,
.footer-five-color .widget.widget_instagram span a:hover {
  color: #ba77ff !important;
}

.footer-five-bottom a:hover {
  color: #ba77ff;
}

/*Start footer_bootom_two css
=====================================*/
.footer_bootom_two {
  background: #f6fcfd;
  color: #666666;
}

.footer_bootom_two a {
  color: #666666;
  opacity: 1;
}

.footer_area_eight {
  background: #24292f;
  padding: 120px 0px 110px;
}

.footer_area_eight .widget h2 {
  font: 600 20px/22px "Raleway", sans-serif;
  color: #fff;
  padding-bottom: 33px;
}

.footer_area_eight .about_us_widget p {
  font: 400 14px/26px "Raleway", sans-serif;
  color: #fff;
  padding: 15px 0px 12px;
}

.footer_area_eight .about_us_widget .copy_wright {
  font: 500 12px/40px "Raleway", sans-serif;
  color: #9fa4a9;
}

.footer_area_eight .about_us_widget .copy_wright a {
  color: #0270e9;
}

.footer_area_eight .link_widget ul li a {
  font: 400 12px/20px "Raleway", sans-serif;
  display: inline-block;
  color: #fff;

  margin-bottom: 3px;
}

.footer_area_eight .link_widget ul li a:hover {
  text-decoration: underline;
  color: #0270e9;
}

.footer_area_eight .social_widget ul li {
  display: inline-block;
}

.footer_area_eight .social_widget ul li a {
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  margin-right: 14px;
}

.footer_area_eight .social_widget ul li a:hover {
  color: #0270e9;
}

/*---------------------------------------------------- */

/*============ new css  =============*/
.n_hero_banner_area {
  min-height: 1010px;
  position: relative;
  z-index: 1;
  padding-top: 250px;
  background-image: -moz-linear-gradient(40deg, #003459 0%, #0095ff 100%);
  background-image: -webkit-linear-gradient(40deg, #003459 0%, #0095ff 100%);
  background-image: -ms-linear-gradient(40deg, #003459 0%, #0095ff 100%);
  overflow: hidden;
}

.n_hero_banner_area svg {
  position: absolute;
  bottom: -29px;
  height: 350px;
  width: 100%;
  left: 0;
}

.shape_banners {
  position: absolute !important;
  right: 0;
  top: 0;
  height: 100%;
  width: 50%;
}

.shape_banners .img1 {
  right: 120px;
  position: absolute;
  top: 176px;
}

.shape_banners .img3 {
  top: 200px;
  position: absolute;
  left: 80px;
}

.shape_banners .img2 {
  left: 24px;
  top: 358px;
  position: absolute;
}

.n_hero_banner_area .mobile_img {
  position: relative;
  z-index: 2;
}

.n_hero_banner_area .mobile_img img {
  max-width: inherit;
  position: relative;
  z-index: 1;
  top: -120px;
}

.n_banner_content {
  color: #fff;
  font-family: "Poppins", sans-serif;
  padding-top: 60px;
  position: relative;
  z-index: 1;
}

.n_banner_content h2 {
  font-size: 50px;
  line-height: 66px;
  font-weight: 700;
}

.n_banner_content p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  padding: 10px 55px 44px 0px;
}

.n_banner_btn {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  border-radius: 3px;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 29, 0.14) !important;

  background-size: 200% auto;
  padding: 18px 33px;
  font-weight: 600;
  /*color: #fff;*/
  display: inline-block;
  transition: all 0.3s linear;

  background-image: linear-gradient(
    120deg,
    rgb(242, 193, 19) 0%,
    rgb(251, 255, 0) 100%
  );
  color: #003459;
}

.n_banner_btn:hover {
  background-position: right center;
  background-image: linear-gradient(
    120deg,
    rgb(251, 255, 0) 0%,
    rgb(242, 193, 19) 100%
  );
  /*color: #fff;*/
}

/*===== about_area css ======*/
.about_area {
  padding-top: 200px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.row_reverse {
  flex-direction: row-reverse;
}

.align_items_center {
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.about_area .about_img {
  margin-left: 30px;
}

.about_area .about_img img {
  max-width: inherit;
}

.f_36 {
  font-size: 36px;
}

.f_30 {
  font-size: 30px;
}

.p_color {
  font-size: 16px;
  line-height: 26px;
  color: #8b8b99;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.h_color {
  color: #282835;
  font-weight: 600;
  line-height: 50px;
}

.about_area .about_img {
  position: relative;
}

.about_area .shape {
  position: absolute;
  top: 0;
  right: 80px;
  z-index: -1;
}

.about_area .about_content p {
  padding-top: 20px;
  font-weight: 300;
}

.about_area .about_content .n_banner_btn {
  margin-top: 40px;
}

.memphis-parallax li {
  position: absolute;
}

.about_area .memphis-parallax li:nth-child(1),
.faq_solution_area .memphis-parallax li:nth-child(1) {
  right: 40%;
  top: 200px;
}

.about_area .memphis-parallax li:nth-child(3),
.faq_solution_area .memphis-parallax li:nth-child(3) {
  right: 5%;
  top: 63%;
}

.about_area .memphis-parallax li:nth-child(2),
.faq_solution_area .memphis-parallax li:nth-child(2) {
  right: 17%;
  top: 120px;
}

.about_area .memphis-parallax li:nth-child(6),
.faq_solution_area .memphis-parallax li:nth-child(6) {
  right: 47%;
  top: 82%;
}

.about_area .memphis-parallax li:nth-child(5),
.faq_solution_area .memphis-parallax li:nth-child(5) {
  right: 46%;
  top: 60%;
}

.about_area .memphis-parallax li:nth-child(7),
.faq_solution_area .memphis-parallax li:nth-child(7) {
  right: 75%;
  top: 95%;
}

.about_area .memphis-parallax li:nth-child(8),
.faq_solution_area .memphis-parallax li:nth-child(8) {
  right: 13%;
  top: 81%;
}

.about_area .memphis-parallax li:nth-child(4),
.faq_solution_area .memphis-parallax li:nth-child(4) {
  right: 32%;
  top: 89%;
}

.best_screen_features_area {
  position: relative;
  padding-bottom: 0;
  z-index: 1;
  background: #f8f8ff;
  overflow: hidden;
}

.best_screen_features_area .memphis-parallax li:nth-child(1) {
  left: 30%;
  top: 58px;
}

.best_screen_features_area .memphis-parallax li:nth-child(3) {
  left: 5%;
  top: 33%;
}

.best_screen_features_area .memphis-parallax li:nth-child(2) {
  left: 8%;
  top: 120px;
}

.best_screen_features_area .memphis-parallax li:nth-child(6) {
  left: 28%;
  top: 86%;
}

.best_screen_features_area .memphis-parallax li:nth-child(5) {
  left: 13%;
  top: 81%;
}

.best_screen_features_area .memphis-parallax li:nth-child(7) {
  left: 38%;
  top: 89%;
}

.best_screen_features_area .memphis-parallax li:nth-child(8) {
  left: 39%;
  top: 40%;
}

.best_screen_features_area .memphis-parallax li:nth-child(4) {
  left: 12%;
  top: 64%;
}

.best_screen_features_area .memphis-parallax li:nth-child(9) {
  left: 28%;
  top: 29%;
}

.best_screen_features_area .memphis-parallax li:nth-child(10) {
  left: 12%;
  top: 34%;
}

.best_screen_features_area svg {
  position: absolute;
  top: -14px;
  width: 100%;
  left: 0;
  z-index: -1;
  bottom: 0;
  height: 277px;
}

.b_screen_img {
  margin-left: -50px;
  margin-top: 65px;
}

.b_features_item {
  padding-left: 60px;
  padding-bottom: 70px;
}

.b_features_item p {
  font-weight: 400;
}

.b_features_icon {
  width: 70px;
  height: 70px;
  text-align: center;
  font-size: 30px;
  line-height: 70px;
  color: #4c84ff;
  border: 1px solid rgba(76, 132, 255, 0.4);
  border-radius: 4px;
  background-color: rgb(76, 132, 255, 0.05);
  margin-bottom: 35px;
  position: relative;
  transition: all 0.2s linear;
}

.b_features_icon .hover_color {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: -moz-linear-gradient(
    180deg,
    rgb(0, 186, 222) 0%,
    rgb(74, 119, 244) 100%
  );
  background-image: -webkit-linear-gradient(
    180deg,
    rgb(0, 186, 222) 0%,
    rgb(74, 119, 244) 100%
  );
  background-image: -ms-linear-gradient(
    180deg,
    rgb(0, 186, 222) 0%,
    rgb(74, 119, 244) 100%
  );
  background-image: linear-gradient(
    180deg,
    rgb(0, 186, 222) 0%,
    rgb(74, 119, 244) 100%
  );
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 29, 0.14);
  transition: all 0.3s linear;
  opacity: 0;
  border-radius: 4px;
}

.b_features_item h3 {
  font-size: 20px;
  line-height: 26px;
  color: #282835;
  font-weight: 600;
  padding-bottom: 15px;
  transition: all 0.2s linear;
}

.b_features_item h3:hover {
  color: #4c84ff;
}

.b_features_item:hover .b_features_icon {
  color: #fff;
  border: 1px solid transparent;
}

.b_features_item:hover .b_features_icon .hover_color {
  opacity: 1;
}

.b_features_item:hover h3 {
  color: #4c84ff;
}

.b_features_info {
  padding-top: 130px;
}

.faq_accordian_two {
  flex: 1;
  align-self: center;
}

.faq_solution_area {
  padding-top: 300px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.faq_solution_area .memphis-parallax li:nth-child(1) {
  right: 33%;
  top: 172px;
}

.faq_solution_area .memphis-parallax li:nth-child(3) {
  right: 18%;
  top: 21%;
}

.faq_solution_area .memphis-parallax li:nth-child(2) {
  right: 17%;
  top: 120px;
}

.faq_solution_area .memphis-parallax li:nth-child(6) {
  right: 18%;
  top: 82%;
}

.faq_solution_area .memphis-parallax li:nth-child(5) {
  right: 54px;
  top: 53%;
}

.faq_solution_area .memphis-parallax li:nth-child(7) {
  right: 33%;
  top: 95%;
}

.faq_solution_area .memphis-parallax li:nth-child(8) {
  right: 18%;
  top: 90%;
}

.faq_solution_area .memphis-parallax li:nth-child(4) {
  right: 10%;
  top: 49%;
}

.faq_solution_area .memphis-parallax li:nth-child(9) {
  right: 48%;
  top: 74%;
}

.faq_solution_area .memphis-parallax li:nth-child(10) {
  right: 45%;
  top: 62%;
}

.faq_solution_area .title-four {
  margin-bottom: 50px;
}

.faq_accordian_two .card {
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 29, 0.08);
  background: #fff;
  border: 0px;
  margin-bottom: 20px;
  border-radius: 0px;
  padding: 0px 30px 25px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.faq_accordian_two .card:before {
  content: "";
  background-image: -moz-linear-gradient(
    120deg,
    rgb(242, 193, 19) 0%,
    rgb(251, 255, 0) 100%
  );
  background-image: -webkit-linear-gradient(
    120deg,
    rgb(242, 193, 19) 0%,
    rgb(251, 255, 0) 100%
  );
  background-image: -ms-linear-gradient(
    120deg,
    rgb(242, 193, 19) 0%,
    rgb(251, 255, 0) 100%
  );
  background-image: linear-gradient(
    120deg,
    rgb(242, 193, 19) 0%,
    rgb(251, 255, 0) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s linear;
}

.faq_accordian_two .card .card-header {
  border: 0px;
  border-radius: 0px;
  background: transparent;
  padding: 0px;
}

.faq_accordian_two .card .card-header h5 .btn-link {
  display: block;
  padding: 25px 0px 0px;
  text-align: left;
  width: 100%;
  font: 500 20px/26px "Poppins", sans-serif;
  color: #8b8b99;
  text-decoration: none;
  border-radius: 0px;
  cursor: pointer;
}

.faq_accordian_two .card .card-header h5 .btn-link i {
  color: #003459;
  font-size: 30px;
  line-height: 26px;
  vertical-align: middle;
  padding-right: 25px;
}

.faq_accordian_two .card .card-body {
  background: transparent;
  padding: 15px 36px 15px 60px;
}

.faq_accordian_two .card .card-body p {
  color: #8b8b99;
}

.faq_accordian_two .card.active:before {
  opacity: 1;
}

.faq_accordian_two .card.active .card-header h5 .btn-link,
.faq_accordian_two .card.active .card-header h5 .btn-link i,
.faq_accordian_two .card.active .card-body p {
  color: #003459;
}

.faq_image_mockup {
  position: relative;
  right: -53px;
}

.faq_solution_area .faq_image_mockup img {
  max-width: inherit;
}

.d_flex {
  display: flex;
}

/*PRICEING AREA CSS */
.priceing_area_two {
  padding: 110px 0px 210px;
}

.price_box_two {
  border: 1px solid rgb(240, 243, 250);
  border-radius: 5px;
  background-color: rgb(252, 252, 255);
  padding: 40px 40px 55px;
  transition: all 0.3s linear;
}

.price_box_two h5 {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 4px;
  color: #282835;
  font-family: "Poppins", sans-serif;
}

.price_box_two p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #8b8b99;
  font-family: "Poppins", sans-serif;
}

.price_icon {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: rgba(3, 218, 246, 0.051);
  display: inline-block;
  position: relative;
  line-height: 200px;
  margin-top: 24px;
}

.price_icon:before {
  content: "";
  width: 140px;
  height: 140px;
  background: #4c84ff;
  opacity: 0.05;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.price_icon img {
  max-width: 100%;
}

.price_box_two .rate {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #282835;
  border-bottom: 1px solid #e8e8f0;
  padding: 25px 0px 17px;
  transition: all 0.3s linear;
}

.price_box_two ul {
  padding: 23px 0px 30px;
}

.price_box_two ul li {
  font-size: 16px;
  line-height: 34px;
  color: #727988;
  font-family: "Poppins", sans-serif;
}

.price_box_two .purchase_btn_two {
  font-size: 14px;
  line-height: 30px;
  color: #4c84ff;
  border: 1px solid #4c84ff;
  border-radius: 3px;
  display: inline-block;
  padding: 9px 35px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  position: relative;
  z-index: 1;
}

.price_box_two .purchase_btn_two:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  background-image: -moz-linear-gradient(
    to right,
    rgb(74, 119, 244) 0%,
    rgb(0, 186, 222) 51%,
    rgb(74, 119, 244) 100%
  );
  background-image: -webkit-linear-gradient(
    to right,
    rgb(74, 119, 244) 0%,
    rgb(0, 186, 222) 51%,
    rgb(74, 119, 244) 100%
  );
  background-image: -ms-linear-gradient(
    to right,
    rgb(74, 119, 244) 0%,
    rgb(0, 186, 222) 51%,
    rgb(74, 119, 244) 100%
  );
  background-image: linear-gradient(
    to right,
    rgb(74, 119, 244) 0%,
    rgb(0, 186, 222) 51%,
    rgb(74, 119, 244) 100%
  );
  background-size: 200% auto;
  transition: all 0.4s linear;
  z-index: -1;
}

.price_box_two:hover {
  border-color: rgb(240, 243, 250);
  background: #fff;
  box-shadow: 0px 30px 50px 0px rgba(0, 0, 29, 0.1);
}

.price_box_two:hover .rate {
  color: #4c84ff;
}

.price_box_two .purchase_btn_two:hover {
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 29, 0.14);
  color: #fff;
  border-color: #18a5e5;
}

.price_box_two .purchase_btn_two:hover:before {
  opacity: 1;
}

.new_call_action_area {
  position: relative;
  z-index: 1;
  background: linear-gradient(0deg, rgba(0, 52, 89, 0.8), rgba(0, 52, 89, 0.6)),
    url(../images/screenshots/download-bg.png) no-repeat;
  background-size: cover;
  background-position: center 0;
}

.new_call_action_area .overlay_bg {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: -1;
  opacity: 0.2;
}

.new_call_action_area .action_mobile img {
  width: auto;
  max-width: inherit;
}

.new_call_action_area .action_mobile {
  position: absolute;
  top: -145px;
  left: -40px;
}

.n_call_action_content {
  color: #fff;
  padding-left: 130px;
  padding-top: 110px;
  padding-bottom: 120px;
}

.n_call_action_content h2 {
  font: 600 36px/50px "Poppins", sans-serif;
}

.n_call_action_content p {
  font-size: 16px;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
  padding: 19px 31px 42px 0px;
}

.action_btn {
  padding: 12px 20px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #fff;
  transition: all 0.3s linear;
  display: inline-block;
}

.action_btn img {
  transition: all 0.3s linear;
}

.action_btn.a_btn_one {
  background: #fff;
}

.action_btn.a_btn_one:hover {
  background: transparent;
  border-color: #fff;
}

.action_btn.a_btn_one:hover img {
  -webkit-filter: brightness(3);
  filter: brightness(3);
}

.action_btn.a_btn_two {
  border: 1px solid #fff;
  margin-left: 17px;
}

.action_btn.a_btn_two:hover {
  background: #fff;
}

.action_btn.a_btn_two:hover img {
  -webkit-filter: brightness(1);
  filter: brightness(1);
}

.action_btn.a_btn_two img {
  -webkit-filter: brightness(3);
  filter: brightness(3);
}

.testimonial_area_two {
  margin-top: -50px;
  z-index: -3;
  padding: 180px 0px 150px;
}

.testimonial_area_two .carousel-control-prev,
.testimonial_area_two .carousel-control-next {
  width: auto;
  height: 25px;
  font-size: 20px;
  color: #8b8b99;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}

.testimonial_area_two .carousel-control-prev:hover,
.testimonial_area_two .carousel-control-next:hover {
  color: #4c84ff;
}

.testimonial_info {
  padding: 50px 170px 35px;
  background: #fff;
}

.testimonial_info .carousel_text {
  text-align: center;
  padding-bottom: 80px;
}

.testimonial_info .carousel_text p {
  font-size: 16px;
  line-height: 30px;
  color: #8b8b99;
  font-family: "Poppins", sans-serif;
  padding-top: 45px;
}

.carousel-indicators {
  position: relative;
}

.carousel-indicators li {
  width: auto;
  height: auto;
  text-indent: 0;
  background: none;
  transform: scale(0.8);
  transition: all 0.3s linear;
}

.carousel-indicators li img {
  opacity: 0.5;
  border-radius: 50%;
}

.carousel-indicators li.active {
  background: none;
  transform: scale(1);
}

.carousel-indicators li .slider_thumbs {
  text-align: center;
}

.carousel-indicators li .slider_thumbs .thumbs_text {
  opacity: 0;
}

.carousel-indicators li.active .slider_thumbs .thumbs_text,
.carousel-indicators li.active img {
  opacity: 1;
}

.carousel-indicators li .slider_thumbs h2 {
  font: 500 16px/25px "Poppins", sans-serif;
  color: #282835;
  margin-top: 25px;
}

.carousel-indicators li .slider_thumbs p {
  font: 400 16px/25px "Poppins", sans-serif;
  color: #8b8b99;
}

.subscribe_area_two {
  padding: 120px 0px;
  z-index: 1;
  position: relative;
}

.subscribe_area_two:before {
  content: "";
  background: url("../images/map_tecture.png") no-repeat scroll center 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.subscribe_area_two .subcribes.input-group .form-control {
  border-width: 1px;
  border-color: rgb(240, 240, 250);
  border-style: solid;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.902);
  box-shadow: 0px 30px 80px 0px rgba(0, 0, 29, 0.1);
  height: 80px;
  font-size: 14px;
  line-height: 22px;
  padding-left: 70px;
  color: #9a9aab;
  position: relative;
  z-index: 0;
}

.subscribe_area_two .subcribes:before {
  content: "\e75a";
  font-family: "themify";
  font-size: 20px;
  position: absolute;
  left: 38px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  color: #99e0e9;
  z-index: 1;
}

.subscribe_area_two .subcribes.input-group .form-control.placeholder {
  color: #9a9aab;
}

.subscribe_area_two .subcribes.input-group .form-control:-moz-placeholder {
  color: #9a9aab;
}

.subscribe_area_two .subcribes.input-group .form-control::-moz-placeholder {
  color: #9a9aab;
}

.subscribe_area_two
  .subcribes.input-group
  .form-control::-webkit-input-placeholder {
  color: #9a9aab;
}

.subscribe_area_two .subcribes.input-group .btn-submit {
  border-radius: 4px !important;
  background-image: -moz-linear-gradient(
    180deg,
    rgb(0, 186, 222) 0%,
    rgb(74, 119, 244) 100%
  );
  background-image: -webkit-linear-gradient(
    180deg,
    rgb(0, 186, 222) 0%,
    rgb(74, 119, 244) 100%
  );
  background-image: -ms-linear-gradient(
    180deg,
    rgb(0, 186, 222) 0%,
    rgb(74, 119, 244) 100%
  );
  background-image: linear-gradient(
    180deg,
    rgb(0, 186, 222) 0%,
    rgb(74, 119, 244) 100%
  );
  top: 10px;
  transform: translateY(0);
  right: 10px;
  color: #fff;
}

a.btn.btn-normal.apps-button span {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 12px;
}

a.btn.btn-normal.apps-button {
  background: #fff;
  padding: 6px 30px 12px 66px;
  margin-right: 20px;
  position: relative;
  text-align: left;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  color: #5a5a67;
  border: 1px solid #fff;
  font-family: "Poppins", sans-serif;
  transition: all 0.3s linear;
}

a.btn.btn-normal.apps-button + a.btn.btn-normal.apps-button {
  padding-right: 15px;
  padding-left: 62px;
  background: transparent;
  color: #fff;
  transition: all 0.3s linear;
}

a.btn.btn-normal.apps-button + a.btn.btn-normal.apps-button i {
  color: #fff;
  transition: all 0.3s linear;
}

a.btn.btn-normal.apps-button i {
  font-size: 28px;
  color: #5a5a67;
  position: absolute;
  left: 26px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s linear;
}

a.btn.btn-normal.apps-button:hover {
  background: transparent;
}

a.btn.btn-normal.apps-button:hover i,
a.btn.btn-normal.apps-button:hover {
  color: #fff;
}

a.btn.btn-normal.apps-button + a.btn.btn-normal.apps-button:hover {
  background: #fff;
  color: #5a5a67;
}

a.btn.btn-normal.apps-button + a.btn.btn-normal.apps-button:hover i {
  color: #5a5a67;
}

.mc4wp-response {
  text-align: center;
  margin-top: 23px;
}

.btn-radious {
  border-radius: 45px;
}

/*============= new css video ========*/
.hero-banner-area.video_bg {
  background-image: -moz-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -webkit-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -ms-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  z-index: 1;
  position: relative;
}

@media (max-width: 767px) {
  .hero-banner-area.video_bg {
    padding: 120px 0px;
  }
}

.hero-banner-area.video_bg:before {
  background: url("../images/banner-video-bg.png") no-repeat scroll center
    bottom/cover;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 280px;
  z-index: -1;
}

.hero-banner-area.video_bg .banner-content {
  margin-top: -100px;
}

@media (max-width: 767px) {
  .hero-banner-area.video_bg .banner-content {
    margin-top: 0px;
  }
}

.hero-banner-area.video_bg #wrapper_bgndVideo {
  z-index: -3 !important;
}

.hero-banner-area.backgroud_slidshow {
  background-image: -moz-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -webkit-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  background-image: -ms-linear-gradient(0deg, #4c84ff 0%, #783dff 100%);
  position: relative;
  z-index: 1;
}

.hero-banner-area.backgroud_slidshow:before {
  background: url("../images/banner-video-bg.png") no-repeat scroll center
    bottom/cover;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 280px;
  z-index: -1;
}

@media (max-width: 767px) {
  .hero-banner-area.backgroud_slidshow {
    height: 700px;
    padding: 150px 0px;
  }
}

.hero-banner-area.backgroud_slidshow .carousel {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.3;
  z-index: -3;
}

.hero-banner-area.backgroud_slidshow .carousel .carousel-inner {
  position: initial;
}

.hero-banner-area.backgroud_slidshow .carousel .carousel-item {
  display: block;
  position: absolute;
  opacity: 0;
  -webkit-transition: 3s ease-out;
  -o-transition: 3s ease-out;
  transition: 3s ease-out;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.hero-banner-area.backgroud_slidshow .carousel .carousel-item.active {
  opacity: 1;
  -webkit-transition: 8s ease-out;
  -o-transition: 8s ease-out;
  transition: 8s ease-out;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.hero-banner-area.backgroud_slidshow .banner-content {
  margin-top: -100px;
}

@media (max-width: 767px) {
  .hero-banner-area.backgroud_slidshow .banner-content {
    margin-top: -70px;
  }
}

@media (max-width: 767px) {
  .hero-banner-area.backgroud_slidshow .banner-content p {
    margin: 20px 0px 34px;
  }
}

.page_contain .banner_btn:last-child {
  margin-left: 30px;
}

/*=============== contact_area css =============*/

.area_left {
  float: left;
}

.area_right {
  text-align: left;
  float: left;
  padding-top: 5px;
  padding-left: 20px;
}

.contact_info {
  padding-bottom: 100px;
}

.contact_info_item {
  text-align: center;
}

.contact_info_item h5 {
  font-size: 20px;
  line-height: 24px;
  color: #282835;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 0px;
}

.contact_info_item p,
.contact_info_item a {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #797988;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 0px;
  transition: color 0.2s linear;
}

.contact_info_item a:hover {
  color: #5d58f7;
}

.contact_info_item .contact-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  font-size: 16px;
  background: #5d58f7;
  color: #fff;
  box-shadow: 0px 0px 0px 5px rgba(93, 88, 247, 0.12);
  display: block;
  margin: 0 auto 28px;
}

.contact_form .form-group .form-control {
  background: #f9f9f9;
  border: 1px solid #f9f9f9;
  border-radius: 3px;
  box-shadow: none;
  font-size: 14px;
  line-height: 30px;
  color: #1a264a;
  height: 64px;
  padding: 6px 30px;
  font-weight: 300;
}

.contact_form .form-group .form-control:focus {
  border-color: rgb(223, 223, 249);
  box-shadow: 0px 10px 20px 0px rgba(0, 28, 59, 0.08);
}

.contact_form .form-group {
  margin-bottom: 20px;
}

.contact_form .form-group .form-control.placeholder {
  color: #a2aab3;
}

.contact_form .form-group .form-control:-moz-placeholder {
  color: #a2aab3;
}

.contact_form .form-group .form-control::-moz-placeholder {
  color: #a2aab3;
}

.contact_form .form-group .form-control::-webkit-input-placeholder {
  color: #a2aab3;
}

.contact_form .form-group:last-child {
  margin-bottom: 0px;
}

.contact_form .form-group textarea.form-control {
  height: 200px;
  padding-top: 15px;
}

.contact_form .form-group .thm-btn {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  background: #4c83ff;
  border-radius: 45px;
  padding: 8px 40px;
  border: 2px solid #4c83ff;
  box-shadow: none;
  text-transform: capitalize;
  transition: background 0.3s linear, color 0.3s linear;
  float: right;
  margin: 10px 0px 0px 0px;
}

.contact_form .form-group .thm-btn:hover {
  background: #fff;
  color: #4c83ff;
}

.google-maps {
  border-width: 8px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  background-color: rgb(239, 239, 248);
  box-shadow: 0px 3px 3px 0px rgba(26, 38, 74, 0.08);
  width: 100%;
  height: 100%;
}

.contact_style {
  padding: 55px 50px 60px;
  border-width: 1px;
  border-color: rgb(243, 243, 243);
  border-style: solid;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 3px 0px rgba(26, 38, 74, 0.08);
}

.form_title h6 {
  font-size: 22px;
  line-height: 30px;
  color: #1a264a;
  font-weight: 700;
  margin-bottom: 25px;
}

/*=========================================================================*/

form {
  width: 100%;
  position: relative;
}

.subscribe_area_two .mchimp-errmessage,
.subscribe_area_two .mchimp-sucmessage {
  color: #4c84ff;
}

.pricing-area.pt_0 {
  padding-top: 0;
}

.media-left img.media-object {
  max-width: 110px;
  border-radius: 5px;
}

.sec-pad {
  padding: 100px 0px;
}

.call-action-area-new .call-action .call-text h2 {
  font-weight: 600;
  padding-bottom: 0;
}

.call-action-area-new .apps_button {
  margin-top: 0;
}

.call-action-area-new .call-text .title-w {
  margin-bottom: 0;
}

.call-action-area-new .call-mobile-img img {
  bottom: 0;
  top: -70px;
}

.call-action-area-new .apps_button a {
  color: #fff;
  border-color: #fff;
}

.analysis_area p {
  color: #585e68;
  margin-bottom: 40px;
}

.google-maps iframe {
  height: 100%;
  width: 100%;
  border: none;
}

.contact_form .form-group .form-control.error + .error {
  font: 13px/24px "Poppins", sans-serif;
  font-weight: normal;
  color: #3fcef7;
  position: absolute;
  margin: 0px;
  width: 100%;
  left: 15px;
  top: 98%;
}

.subscribe_area_two:before {
  content: "";
  background: url(../images/map_tecture.png) no-repeat scroll center 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.about_area {
  padding: 150px 0px 120px 0px;
}

.best_screen_features_area {
  padding: 15px 0px 240px 0px;
}

.new_call_action_area .action_mobile {
  left: 0;
}

a.btn.btn-normal.apps-button {
  margin-bottom: 20px;
}

.app-deatails-area {
  padding: 320px 0px 140px 0px;
}

.testimonial_area_two {
  background: #f8f8ff;
  padding: 380px 0px 112px 0px;
}

.app-deatails-area .app_info {
  padding: 60px 0;
}

.app-details {
  padding: 0px 70px 0px 100px;
}

.testimonial_area_two .carousel-control-prev {
  left: 40px;
}

.testimonial_area_two .carousel-control-next {
  right: 40px;
}

.priceing_area_two {
  background-image: url(../images/pricing-table.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: fixed;
}

.features_area {
  padding: 10px 0px 70px 0px;
}

.features_area.home2_features {
  padding: 105px 0px 70px 0px;
  background: #f9f9ff;
}

.features_area .f_img {
  padding: 130px 0px 0px 75px;
}

.home2_features .f_img {
  padding: 0px 0px 55px 0px;
}

.features_mb_custom .scree_feature_item {
  margin-bottom: 0;
}

.what_offer_wrap {
  padding: 115px 0 0;
}

.what_title {
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  color: #282835;
  margin-bottom: 20px;
}

.what_para {
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;
  color: #8b8b99;
  margin-bottom: 57px;
}

.new_featured_item {
  margin-bottom: 95px;
  padding-right: 20px;
}

.new_featured_item i {
  font-size: 36px;
  color: #7e23d3;
}

.new_featured_item h4 {
  font-size: 18px;
  color: #282835;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin: 30px 0px 22px;
}

.new_featured_item p {
  font-size: 15px;
  line-height: 26px;
  color: #8b8b99;
  font-weight: 300;
}

.shape_8 {
  background-image: url(../images/Shape-8.png);
}

.hero-banner-area.home2hero {
  height: 1000px;
  position: relative;
  background: url(../images/banner/banner.png) no-repeat scroll center
    center/cover;
  overflow: hidden;
}

.banner-content {
  max-width: 555px;
}

.banner-content h1 {
  font-weight: 600;
  line-height: 60px;
}

.banner-content p {
  font: 400 18px/35px "Poppins", sans-serif;
  margin: 34px 0px 40px;
}

.home2hero .banner_btn {
  font-weight: 500;
  text-transform: inherit;
  font-size: 16px;
}

.home2hero .banner_btn + .banner_btn {
  margin-left: 25px;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
}

.home2hero .btn-transparent {
  border: 0px;
  color: #fff;
  position: relative;
  min-width: auto;
}

.home2hero .btn-transparent:hover {
  background: transparent;
}

.home2hero .btn-transparent:before {
  content: "";
  width: 100%;
  height: 1px;
  bottom: 13px;
  left: 0;
  position: absolute;
  background: #fff;
}

.home_2_features .title-four h2 {
  font-size: 36px;
}

.home_2_features .title-four p,
.home2_testimonial .title-four p,
.home_2_price .title-four p {
  font-size: 16px;
}

.video_area {
  background: #f6f5ff;
}

.features-area-five .title-four p {
  padding-bottom: 45px;
}

.home2_testimonial {
  background-image: url(../images/testimonial_bg.png);
  background-size: cover;
}

.home2_call_to_action {
  padding: 190px 0 130px;
}

.home2_call_to_action .call-action .call-text h2 {
  padding: 60px 0 20px;
}

.home2_call_to_action .call-mobile-img img {
  top: 0;
}

.page-main .page_contain .banner_btn {
  border-color: #ba77ff;
  background: #ba77ff;
}

.page-main .page_contain .banner_btn:hover {
  background: transparent;
  color: #ba77ff;
}

.page-main .page_contain .banner_btn + .banner_btn {
  background: transparent;
  color: #ba77ff;
}

.page-main .page_contain .banner_btn + .banner_btn:hover {
  background: #ba77ff;
  color: #fff;
}

.about_area {
  padding: 100px 0px 115px 0px;
}

.best_screen_features_area {
  padding: 15px 0px 240px 0px;
}

.new_call_action_area .action_mobile {
  left: 0;
}

.best_screen_features_area .title-four.text-center {
  margin-bottom: 20px;
}

a.btn.btn-normal.apps-button {
  margin-bottom: 20px;
}

.app-deatails-area .app_info {
  padding: 60px 0 40px;
}

.app-details {
  padding: 0px 70px 0px 100px;
}

.testimonial_area_two .carousel-control-prev {
  left: 40px;
}

.testimonial_area_two .carousel-control-next {
  right: 40px;
}

.priceing_area_two {
  background-image: url(../images/pricing-table.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: fixed;
}

.features_area {
  padding: 10px 0px 70px 0px;
}

.features_area.home2_features {
  padding: 105px 0px 70px 0px;
  background: #f9f9ff;
}

.features_area .f_img {
  padding: 130px 0px 0px 75px;
}

.home2_features .f_img {
  padding: 0px 0px 55px 0px;
}

.features_mb_custom .scree_feature_item {
  margin-bottom: 0;
}

.what_offer_wrap {
  padding: 115px 0 0;
}

.what_title {
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  color: #282835;
  margin-bottom: 20px;
}

.what_para {
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;
  color: #8b8b99;
  margin-bottom: 57px;
}

.new_featured_item {
  margin-bottom: 95px;
  padding-right: 20px;
}

.new_featured_item i {
  font-size: 36px;
  color: #7e23d3;
}

.new_featured_item h4 {
  font-size: 18px;
  color: #282835;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin: 30px 0px 22px;
}

.new_featured_item p {
  font-size: 15px;
  line-height: 26px;
  color: #8b8b99;
  font-weight: 300;
}

.shape_8 {
  background-image: url(../images/Shape-8.png);
}

.hero-banner-area.home2hero {
  height: 1000px;
  position: relative;
  background: url(../images/banner/banner.png) no-repeat scroll center
    center/cover;
  overflow: hidden;
}

.banner-content {
  max-width: 555px;
}

.banner-content h1 {
  font-weight: 600;
  line-height: 60px;
}

.banner-content p {
  font: 400 18px/35px "Poppins", sans-serif;
  margin: 34px 0px 40px;
}

.home2hero .banner_btn {
  font-weight: 500;
  text-transform: inherit;
  font-size: 16px;
}

.home2hero .banner_btn + .banner_btn {
  margin-left: 25px;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
}

.home2hero .btn-transparent {
  border: 0px;
  color: #fff;
  position: relative;
  min-width: auto;
}

.home2hero .btn-transparent:hover {
  background: transparent;
}

.home2hero .btn-transparent:before {
  content: "";
  width: 100%;
  height: 1px;
  bottom: 13px;
  left: 0;
  position: absolute;
  background: #fff;
}

.home_2_features .title-four h2 {
  font-size: 36px;
}

.home_2_features .title-four p,
.home2_testimonial .title-four p,
.home_2_price .title-four p {
  font-size: 16px;
}

.video_area {
  background: #f6f5ff;
}

.features-area-five .title-four p {
  padding-bottom: 45px;
}

.home2_testimonial {
  background-image: url(../images/testimonial_bg.png);
  background-size: cover;
}

.home2_call_to_action {
  padding: 190px 0 130px;
}

.page-main .page_contain .banner_btn {
  border-color: #ba77ff;
  background: #ba77ff;
}

.page-main .page_contain .banner_btn:hover {
  background: transparent;
  color: #ba77ff;
}

.page-main .page_contain .banner_btn + .banner_btn {
  background: transparent;
  color: #ba77ff;
}

.page-main .page_contain .banner_btn + .banner_btn:hover {
  background: #ba77ff;
  color: #fff;
}

.footer-five .footer-top {
  background: #2a2a2a;
}

.footer-five .footer_bottom,
.footer_bottom a {
  color: #757887;
}

.footer-five .footer_bottom {
  background: #242424;
}

.footer-five .footer-top .footer_sidebar .widget .widget_title_two {
  color: #ffffff;
}

.footer_sidebar p,
.footer_sidebar .widget_recent_comments ul li,
.footer_sidebar .widget_recent_comments ul li a,
.footer_sidebar a.rsswidget,
.footer_sidebar .rssSummary,
.footer-widget .tagcloud a,
.footer-widget cite,
.footer-widget .widget_rss span.rss-date,
.footer_sidebar .widget_recent_entries li a,
.footer-five
  .footer-top
  .footer_sidebar
  .widget.widget_social
  .social-icon
  li
  a,
.footer_sidebar .widget.widget_nav_menu ul li a,
.footer_sidebar .widget.widget_meta ul li a,
.footer_sidebar .widget.widget_pages ul li a,
.footer_sidebar .widget.widget_archive ul li a,
.footer_sidebar .widget.widget_categories ul li a,
.footer-top .footer_sidebar .widget.widget_contact ul li .fleft,
.footer-five .footer-top .footer_sidebar .widget.widget_contact ul li .fleft a,
.footer-five .footer-top .footer_sidebar .widget.widget_contact ul li i,
.footer-top .footer_sidebar .widget.widget_twitter .tweets li .tweets-text {
  color: #c9ccdb;
}

.footer-five
  .footer-top
  .footer_sidebar
  .widget.widget_social
  .social-icon
  li
  a:hover,
.footer_sidebar .widget.widget_nav_menu ul li a:hover,
.footer-five
  .footer-top
  .footer_sidebar
  .widget.widget_contact
  ul
  li
  .fleft
  a:hover {
  color: #4c84ff;
}

.footer-five .footer_bottom a {
  color: #b1b1b1;
}

.footer-five .footer_bottom a:hover {
  color: #4c84ff;
}

.single-img {
  width: 33%;
}

/*      landing page       */

.hero-four {
  background: url(../images/home-5/banner.jpg) no-repeat scroll center 0;
  height: 950px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.call-action-area .video-bg {
  background: url(../images/home/call-action-bg.jpg) no-repeat scroll center
    center/cover;
}

.hero-banner-area {
  height: 1000px;
  position: relative;
  background: url(../images/banner/banner.png) no-repeat scroll center
    center/cover;
  overflow: hidden;
}

.videoWrapper .videoPoster {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 0;
  outline: none;
  background-position: 0 50%;
  background: url(../images/home/video-bg.jpg) no-repeat scroll center 0;
  background-size: cover;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: opacity 800ms, height 0s;
  -moz-transition: opacity 800ms, height 0s;
  transition: opacity 800ms, height 0s;
  -webkit-transition-delay: 0s, 0s;
  -moz-transition-delay: 0s, 0s;
  transition-delay: 0s, 0s;
  z-index: 1;
}

.features-area3 {
  padding-top: 50px;
}

.priceing_area_two {
  background: #f8f8ff;
}

.analysis_area {
  background: #f8f8ff;
}

.testimonial-area {
  background: #f8f8ff;
}

.overview-area {
  background: #f8f8ff;
}

.team_area_solid {
  background: #f8f8ff;
}

.features-area3 .tab-content .tab-pane .feat-img {
  margin-bottom: 80px;
}
